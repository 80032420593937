import React from "react";
import { Container, Typography, Button, Box, Grid } from "@mui/material";
import { navigateToDashboard } from "../../utils";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useDashboardUrl } from "../../hooks/useDashboardUrl";
import { useEnvironment } from "../../hooks/useEnvironment";
import { Logo } from "@proximie/components";
import { Link } from "react-router-dom";

export interface CatchAllErrorPageProps {
  errorString: string;
}

type ErrorDetails = {
  title: string;
  description: string;
};

const errorDetails: Record<string, ErrorDetails> = {
  "Media session not found with id:": {
    title: "Session not found",
    description: "You are not invited to this session",
  },
  "Session in incorrect state": {
    title: "Session ended",
    description: "The session you are trying to join has ended.",
  },
  "Cannot join session": {
    title: "Something went wrong",
    description:
      "Couldn't join session.  It could be connection or server issue.  Refresh or try again later",
  },
};

const CatchAllErrorPage = (props: CatchAllErrorPageProps): JSX.Element => {
  const dashboardUrl = useDashboardUrl();
  const { domain } = useEnvironment();
  const key = Object.keys(errorDetails).find((myKey: string): boolean =>
    props.errorString.includes(myKey),
  );
  const details = key
    ? errorDetails[key]
    : {
        title: "Something went wrong",
        description:
          "It may be that you have not got a strong connection, you are not invited to this session, or our server is down.",
      };

  return (
    <Box width={"100%"} minHeight="100%" display="flex" flexDirection="column">
      <Box
        p={2}
        position="fixed"
        top={0}
        left={0}
        height="64px"
        display={"flex"}
      >
        <Link to={dashboardUrl ?? ""}>
          <Logo variant="dashboard-app" data-testid={"Nav logo"} />
        </Link>
      </Box>

      <Container
        component="main"
        maxWidth="xs"
        sx={{
          alignItems: "center",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          rowSpacing={2}
        >
          <Grid item xs>
            <InfoOutlinedIcon fontSize="large" sx={{ height: "48px" }} />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h4">{details.title}</Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="body2" align="center" paragraph>
              {details.description}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Button
              color="secondary"
              variant="contained"
              size="medium"
              onClick={() => navigateToDashboard(domain, dashboardUrl)}
            >
              Back to dashboard
            </Button>{" "}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default CatchAllErrorPage;
