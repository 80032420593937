import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import ShapeSelector from "./ShapeSelector";
import { Icon } from "@proximie/components";
import { Delete, Redo, Undo } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
  MaskHandlerEvents,
  Shapes,
  VideoMaskHandler,
} from "../../../lib/CanvasUtils";
import WebRTCUtil from "../../../lib/WebRTCUtil";
import { FeedType } from "@proximie/common";

export interface EditorControlsProps {
  setIsPtz: (isPtz: boolean) => void;
  maskHandler?: VideoMaskHandler | null;
  edit?: boolean;
}

const EditorControls = (props: EditorControlsProps) => {
  const { colors } = useTheme();
  const { maskHandler, setIsPtz } = props;

  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [canEraseOrDelete, setCanEraseOrDelete] = useState(false);
  const [maskingShape, setMaskingShape] = useState<Shapes | null>(null);
  const [isPromptDelete, setIsPromptDelete] = useState(false);
  const [isNoDeleteWarning, setIsNoDeleteWarning] = useState(false);
  const isEraseModeRef = useRef(false);
  const [isEraseMode, setIsEraseMode] = useState<boolean | null>(false);

  useEffect(() => {
    const handleSelectionChanged = () => {
      if (
        maskHandler &&
        maskHandler.getIsObjectSelected() &&
        isEraseModeRef.current
      ) {
        handleDelete();
      }
    };
    const handleHistoryChanged = () => {
      if (maskHandler) {
        setCanEraseOrDelete(maskHandler.containsMasks());
        !maskHandler.containsMasks() && setEraseMode(false);
        setCanUndo(maskHandler.canUndo());
        setCanRedo(maskHandler.canRedo());
      }
    };
    const handleSelectedMaskingShapeChanged = () => {
      if (maskHandler) {
        setMaskingShape(maskHandler.getSelectedMaskingShape());
      }
    };

    const handleIsEraseModeChanged = () => {
      if (maskHandler) {
        setIsEraseMode(maskHandler.getIsEraseMode());
      }
    };

    if (maskHandler) {
      handleSelectionChanged();
      handleHistoryChanged();
      handleSelectedMaskingShapeChanged();
      handleIsEraseModeChanged();

      maskHandler.on(MaskHandlerEvents.ObjectSelectedChanged, () =>
        handleSelectionChanged(),
      );
      maskHandler.on(MaskHandlerEvents.HistoryChanged, handleHistoryChanged);
      maskHandler.on(
        MaskHandlerEvents.SelectedMaskingShapeChanged,
        handleSelectedMaskingShapeChanged,
      );
      maskHandler.on(
        MaskHandlerEvents.IsEraseModeChanged,
        handleIsEraseModeChanged,
      );
    }

    const stream = maskHandler?.getInputStream();
    if (stream && maskHandler?.getDevice().mediaType !== FeedType.Screen) {
      setIsPtz(WebRTCUtil.isPTZ(stream));
    }

    return () => {
      if (maskHandler) {
        maskHandler.off(
          MaskHandlerEvents.ObjectSelectedChanged,
          handleSelectionChanged,
        );
        maskHandler.off(MaskHandlerEvents.HistoryChanged, handleHistoryChanged);
        maskHandler.off(
          MaskHandlerEvents.SelectedMaskingShapeChanged,
          handleSelectedMaskingShapeChanged,
        );
        maskHandler.off(
          MaskHandlerEvents.IsEraseModeChanged,
          handleIsEraseModeChanged,
        );
      }
    };
  }, [maskHandler, setIsPtz]);

  //side panel funtions
  const theme = useTheme();

  const btnSxSize = {
    minHeight: "56px",
    minWidth: "56px",
    borderColor: theme.palette.text.disabled,
  };

  const setEraseMode = (eraseMode: boolean) => {
    isEraseModeRef.current = eraseMode;
    setIsEraseMode(eraseMode);
    if (maskHandler) {
      maskHandler.setIsEraseMode(eraseMode);
      isEraseModeRef.current && maskHandler.deselect();
    }
  };

  const handleSelectedMaskingShapeChange = (
    _event: React.MouseEvent<HTMLElement>,
    newShape: Shapes,
  ) => {
    if (maskHandler) maskHandler.setSelectedMaskingShape(newShape);
  };

  const handleDelete = () => {
    if (maskHandler) {
      if (maskHandler.getIsRemoteEdit() && !isNoDeleteWarning) {
        // prompt confirm delete
        setIsPromptDelete(true);
      } else applyDelete();
    }
  };
  const applyDelete = () => {
    setIsPromptDelete(false);
    if (maskHandler) maskHandler.emitDelete();
  };

  const handleUndoClick = () => {
    if (maskHandler) maskHandler.undo();
  };

  const handleRedoClick = () => {
    if (maskHandler) maskHandler.redo();
  };

  const handleClearAllClicked = () => {
    if (maskHandler) maskHandler.clearAllMasks();
  };

  return (
    <>
      <Box
        margin="0px 8px"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="flex-start"
      >
        {!props.edit && (
          <Box margin="8px 2px">
            <Typography color="black" fontWeight="600" fontSize="14px">
              Privacy Control
            </Typography>
          </Box>
        )}
        <Box
          margin="8px 2px"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="flex-start"
        >
          <ShapeSelector
            maskingShape={maskingShape}
            onChange={handleSelectedMaskingShapeChange}
            isDisabled={Boolean(isEraseMode)}
          />
          <Typography variant="caption">Shape</Typography>
        </Box>
        <Box display="flex">
          <Box
            margin="8px 2px"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="flex-start"
          >
            <Button
              variant="outlined"
              sx={{
                ...btnSxSize,
                color: theme.palette.text.secondary,
                bgcolor: "white",
                "&:hover": {
                  borderColor: colors.BlackA400,
                },
                "&:disabled": {
                  borderColor: colors.Grey300,
                },
              }}
              disabled={!canUndo}
              onClick={handleUndoClick}
              data-testid="undo-button"
            >
              <Undo />
            </Button>
            <Typography variant="caption">Undo</Typography>
          </Box>
          <Box
            margin="8px 2px"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="flex-start"
          >
            <Button
              variant="outlined"
              sx={{
                ...btnSxSize,
                color: theme.palette.text.secondary,
                bgcolor: "white",
                "&:hover": {
                  borderColor: colors.BlackA400,
                },
                "&:disabled": {
                  borderColor: colors.Grey300,
                },
              }}
              disabled={!canRedo}
              onClick={handleRedoClick}
              data-testid="redo-button"
            >
              <Redo />
            </Button>
            <Typography variant="caption">Redo</Typography>
          </Box>
        </Box>
        <Box display="flex">
          <Box
            margin="8px 2px"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="flex-start"
          >
            <Button
              variant="outlined"
              sx={{
                ...btnSxSize,
                bgcolor: "white",
                color: theme.palette.text.secondary,
                "&:hover": {
                  borderColor: colors.BlackA400,
                },
                "&:disabled": {
                  borderColor: colors.Grey300,
                },
              }}
              disabled={!canEraseOrDelete || maskingShape !== null}
              onClick={() => setEraseMode(!isEraseModeRef.current)}
              data-testid="eraser-button"
            >
              <Icon
                name={"eraser"}
                color={isEraseMode ? "secondary" : "inherit"}
              />
            </Button>
            <Typography variant="caption">Erase</Typography>
          </Box>
          <Box
            margin="8px 2px"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="flex-start"
          >
            <Button
              variant="outlined"
              sx={{
                ...btnSxSize,
                color: theme.palette.text.secondary,
                bgcolor: "white",
                "&:hover": {
                  color: colors.Red600,
                  bgcolor: colors.Red50,
                  borderColor: colors.BlackA400,
                },
                "&:disabled": {
                  borderColor: colors.Grey300,
                },
              }}
              disabled={!canEraseOrDelete}
              onClick={handleClearAllClicked}
              data-testid={"clear-all-button"}
            >
              <Delete />
            </Button>
            <Typography variant="caption">Clear all</Typography>
          </Box>
        </Box>
      </Box>

      <Dialog
        open={isPromptDelete}
        onClose={() => {
          setIsPromptDelete(false);
        }}
        maxWidth="xs"
      >
        <DialogTitle sx={{ m: 1, p: 2 }}>
          Are you sure you want to delete this mask?
          <IconButton
            aria-label="close"
            onClick={() => {
              setIsPromptDelete(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography gutterBottom style={{ whiteSpace: "pre-line" }}>
            You are deleting a privacy mask on a participant&apos;s video feed.
            This mask will no longer be visible when you click &ldquo;Apply
            changes&ldquo;.
          </Typography>
          <Box my={2}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox />}
                label="Don't show this again"
                onChange={(event) => {
                  setIsNoDeleteWarning(
                    (event as React.ChangeEvent<HTMLInputElement>).target
                      .checked,
                  );
                }}
              />
            </FormGroup>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              applyDelete();
            }}
            data-testid={"pii-confirm-delete"}
          >
            Delete
          </Button>
          <Button
            variant="outlined"
            color={"secondary"}
            onClick={() => {
              setIsPromptDelete(false);
            }}
            data-testid={"pii-cancel-delete"}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default EditorControls;
