import { EventEmitter } from "events";
import Connection, { ConnectionOptions } from "../Connections/Connection";
import Endpoint from "../Endpoints/Endpoint";
import { DeviceType, FeedType } from "@proximie/common";

//eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DeviceOptions {}

export default abstract class Device extends EventEmitter {
  public abstract deviceType: DeviceType;
  public label = "";
  public abstract mediaType: FeedType;

  constructor(public deviceId: string, public options?: DeviceOptions) {
    super();
  }

  public abstract invoke(
    endpoint: Endpoint,
    streamId: string,
    connectionOptions: ConnectionOptions,
  ): Connection | null;

  public close(_error?: Error): Promise<void> {
    return Promise.resolve();
  }

  get state(): unknown {
    return {};
  }

  get services(): unknown {
    return {};
  }

  // return a string that it unique across all devices
  // (this is normally just deviceId but maybe more interesting in
  // DeviceDcp)
  get uniqueId(): string {
    return `${this.deviceType}:${this.deviceId}`;
  }
}
