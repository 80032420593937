import useSWR, { SWRConfiguration } from "swr";
import axios from "axios";
import { UserRole } from "./UserRole";
import { useEnvironment } from "../useEnvironment";

export const useUserRole = (
  sessionId: string | undefined,
  participantId: string | undefined,
  opts?: SWRConfiguration,
) => {
  const { apiUrl } = useEnvironment();

  const getUserRole: (
    sessionId: string,
    participantId: string,
  ) => () => Promise<UserRole> =
    (sessionId: string, participantId: string) => async () =>
      (
        await axios.get(
          `${apiUrl}/api/v2/sessions/${sessionId}/participants/${participantId}/roles`,
        )
      ).data;

  const { data, error } = useSWR<UserRole>(
    sessionId != null && participantId != null ? `role` : null,
    getUserRole(sessionId || "", participantId || ""),
    opts,
  );

  return {
    role: data?.name,
    isRoleLoading: !error && !data,
    roleError: error,
  };
};
