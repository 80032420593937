/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable sonarjs/cognitive-complexity */
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { ConnectionMetadata, DeviceMetadata, DeviceType, ExceptionCodes, exceptions, FeedType,  } from "@proximie/common";
import { PtzOwner } from "../utils/PtzOwner";
import { PtzUser } from "../utils/PtzUser";
import ServerAdapterContext, {
  SendMediaOptionsInterface,
} from "../models/ServerAdapterContext";
import { useSessionContext } from "./session-context/session-context";
import {
  audioParticipantsToParticipants,
  audioParticipantToParticipant,
  serverParamsToMediaServerParams,
} from "../utils";
import { ErrorDialogState } from "../models/ErrorDialogState";
import { SnackbarContext } from "@proximie/components";
import SessionContext from "../models/SessionContext";
import {
  IDevicePresencePayload,
  IWebRtcMediaResourceController,
  lookupWebRtcReferenceFromLabel,
  makeSessionRootTopicPath,
  ResourceReferenceType,
} from "@proximie/dcp";
import {
  DeviceSessionLifeCycle,
  IBrokerOptions,
  ILifeCycleDevice,
  SessionRemoteDeviceManager,
} from "@proximie/dcp-mqtt";
import { useAuthenticatedUser } from "./media-client-authenticated-user-context";
import { liveApiSockets } from "@proximie/dataregion-api";
import { FeatureFlags } from "../components/constants";
import { useFlag } from "@proximie/feature-flag-react-sdk";
import { useTranslation } from "react-i18next";
import SocketIoClientWrapper from "../wrappers/SocketIOClientWrapper/SocketIOClientWrapper";
import { useUser } from "../hooks/useUser";
import { useSession } from "../hooks/useSession";
import { useMembers } from "../hooks/useMembers";
import { user } from "@proximie/api";
import { useTimeout } from "libs/components/src/utils/hooks";
import { useEnvironment } from "../hooks/useEnvironment";
import Connection from "../../lib/ServerAdapter/Connections/Connection";
import OutgoingVideo from "../../lib/models/OutgoingVideo";
import Monitor from "../../lib/ServerAdapter/Monitors/Monitor";
import Device from "../../lib/ServerAdapter/Devices/Device";
import DeviceDcp, { DcpType } from "../../lib/ServerAdapter/Devices/DeviceDcp";
import { AudioParticipants } from "../../lib/models/AudioParticipant";
import IncomingVideo from "../../lib/models/IncomingVideo";
import ServerAdapter from "../../lib/ServerAdapter/ServerAdapter";
import DeviceLocalSpeaker from "../../lib/ServerAdapter/Devices/DeviceLocalSpeaker";
import DeviceLocalMicrophone from "../../lib/ServerAdapter/Devices/DeviceLocalMicrophone";
import MediaUtil from "../../lib/MediaUtil";
import WebRTCUtil from "../../lib/WebRTCUtil";
import MediaParticipants from "../../lib/models/Participants";
import { VideoMaskHandler } from "../../lib/CanvasUtils";

export const serverAdapterContext = createContext<ServerAdapterContext | null>(
  null,
);
export const UseServerAdapterContext = () => useContext(serverAdapterContext);

const VALID_EXCEPTIONS_CODES = [
  "ENDPOINT_NOT_INITIALIZED",
  "VIDEO_CAPACITY_EXCEPTION_EXCEEDED",
];

interface ServerAdapterProviderProps {
  children: ReactNode;
}

export type PtzUsersStore = Record<string, PtzUser>;

function generateVirtualDeviceId(
  profileId: number,
  mediaDeviceId: string,
): string {
  return `${profileId}:${mediaDeviceId}`;
}

function buildBrokerOptions(
  brokerConfig: liveApiSockets.DcpBrokerConfig,
): IBrokerOptions {
  const homeUrlObj = new URL(brokerConfig.wssUrl);
  return {
    protocol: homeUrlObj.protocol === "wss:" ? "wss" : "ws",
    host: homeUrlObj.hostname,
    port: Number(homeUrlObj.port),
    path: homeUrlObj.pathname,
    url: brokerConfig.wssUrl,
    reconnectPeriod: 5000,
    connectTimeout: 10000,
    isRetryInitialConnection: true,
  };
}

export function handlePtzUpdates(
  ptzUsers: PtzUsersStore,
  added: Connection[],
  deleted: Connection[],
  remoteDeviceManager: SessionRemoteDeviceManager,
  profileId?: number,
  socket?: SocketIoClientWrapper,
): void {
  added.forEach((connection: Connection): void => {
    if (!connection.params.devices) {
      // do nothing if there is no devices
      return;
    }

    // it would be better to read the capabilites from the DCP services topic
    // but it is quite involved (particularly since the topic and the Janus
    // connection may appear in different orders).  Also, I plan to re-visit all of
    // this soon....
    if (!connection.params.capabilities?.canPTZ) {
      return;
    }

    if (ptzUsers[connection.streamId]) {
      return;
    }

    const deviceList = connection.params.devices || [];

    const ptzUser = new PtzUser(
      profileId || 0,
      deviceList,
      socket || undefined,
    );

    let isPtzDevice = true;
    deviceList.forEach((deviceInfo: DeviceMetadata): void => {
      const device = remoteDeviceManager.getDevice(deviceInfo.deviceId);
      console.debug(
        { deviceId: deviceInfo.deviceId },
        "handlePtzUpdates=",
        device,
      );
      if (device) {
        isPtzDevice =
          isPtzDevice ||
          !!Object.values(
            device.devicePresence.assembly?.references || [],
          ).find(
            (reference: ResourceReferenceType): boolean =>
              reference.type === "webrtc-media-device",
          );

        try {
          ptzUser.addOrUpdateDeviceWithComponents(device, {
            subset: "all",
          });
        } catch (error) {
          console.warn(
            { streamId: connection.streamId },
            "Failed to add device - error=",
            error,
          );
        }
      } else {
        console.warn(
          { streamId: connection.streamId },
          "Device not found=",
          deviceInfo.deviceId,
        );
      }
    });

    if (isPtzDevice) {
      console.debug({ streamId: connection.streamId }, "Adding PTZ User");
      ptzUsers[connection.streamId] = ptzUser;
    }
  });

  deleted.forEach((connection: Connection): void => {
    if (ptzUsers[connection.streamId]) {
      ptzUsers[connection.streamId].shutdown();
      delete ptzUsers[connection.streamId];
    }
  });
}

export async function handleDeviceUpdates(options: {
  mediaSessionId: string;
  ptzUsers: PtzUsersStore;
  ptzOwners: Record<string, DeviceSessionLifeCycle>;
  deviceId: string;
  presence: IDevicePresencePayload;
  token: string;
  remoteDeviceManager: SessionRemoteDeviceManager;
  homeBrokerConfig?: liveApiSockets.DcpBrokerConfig;
  sessionBrokerConfig?: liveApiSockets.DcpBrokerConfig;
}): Promise<void> {
  console.debug("Device update", options.deviceId, options.presence);

  Object.entries(options.ptzUsers)
    .filter(([_streamId, ptzUser]: [string, PtzUser]): boolean =>
      ptzUser.hasDeviceId(options.deviceId),
    )
    .forEach(([streamId, ptzUser]: [string, PtzUser]): void => {
      const device = options.remoteDeviceManager?.getDevice(options.deviceId);
      console.log("Adding device=", device, options.deviceId, streamId);
      if (!device) {
        return;
      }

      if (!options.presence.available) {
        if (ptzUser.hasDevice(device)) {
          ptzUser.removeDevice(device);
          // delete the PTZ user if there's no devices left on it
          if (ptzUser.devices.length === 0) {
            delete options.ptzUsers[streamId];
          }
        }
      } else {
        ptzUser.addOrUpdateDeviceWithComponents(device, {
          subset: "all",
        });
      }
    });
}

async function createVirtualOwner(
  virtualDeviceId: string,
  ptzOwners: Record<string, DeviceSessionLifeCycle>,
  context: SessionContext,
  tokenProvider: () => string,
  connection: OutgoingVideo,
  controls?: Record<string, boolean>,
): Promise<void> {
  if (ptzOwners[virtualDeviceId]) {
    console.debug(
      "Adding connection to existing device - deviceId=",
      virtualDeviceId,
    );
    (ptzOwners[virtualDeviceId].deviceInfo.device as PtzOwner).addConnection(
      connection,
    );
    return;
  }

  // if we specify controls then we assume that this device is not lockable
  const socket = controls ? null : context.socket || null;
  console.log("componentName=", virtualDeviceId);

  const myDevice = new PtzOwner(virtualDeviceId, connection, socket, controls);

  const deviceInfo: ILifeCycleDevice = {
    device: myDevice,
    deviceId: virtualDeviceId,
    credentials: {
      username: "JWT",
      password: tokenProvider(),
    },
  };

  const lifecycle = new DeviceSessionLifeCycle(
    makeSessionRootTopicPath({
      sessionId: context.sessionParams?.mediaSessionId || "",
    }),
    deviceInfo,
  );

  lifecycle.on("error", (error: Error) =>
    console.warn("Received error:", error),
  );

  //LATER - we should be using the connection established in ServerAdapter.dcpEndpoint.manager
  await lifecycle.connect(
    buildBrokerOptions(
      context.serverParams?.dcpSessionBroker || {
        wssUrl: "",
        mqttsUrl: "",
      },
    ),
  );

  ptzOwners[virtualDeviceId] = lifecycle;
}

export async function sendCamera(
  device: Device,
  dcpDeviceId: string,
  ptzOwners: Record<string, DeviceSessionLifeCycle>,
  context: SessionContext,
  tokenProvider: () => string,
  sendCameraFunc: (
    device: Device,
    stream?: MediaStream,
    params?: ConnectionMetadata,
  ) => Promise<OutgoingVideo>,
  dcpStreamMonitor: Monitor | null,
  user?: user.User,
  options?: SendMediaOptionsInterface,
): Promise<OutgoingVideo> {
  console.debug(
    { deviceId: device.deviceId },
    "sendCamera - options=",
    options,
    device,
  );

  if (
    !context.serverParams?.dcpHomeBroker ||
    !context.serverParams?.dcpSessionBroker ||
    !context.sessionParams?.mediaSessionId
  ) {
    throw new Error("Not configured");
  }

  const isPTZ = options?.stream ? WebRTCUtil.isPTZ(options.stream) : false;

  const virtualDeviceId = generateVirtualDeviceId(
    user?.profileId ?? 0,
    device.deviceId,
  );

  const params: ConnectionMetadata = {
    label: device.label,
    capabilities: {
      canMask: true,
      canPTZ: false,
    },
    devices: [],
  };

  if (dcpDeviceId) {
    let refName = "";
    const dcpDevice = ((dcpStreamMonitor?.deviceList as DeviceDcp[]) || [])
      .filter((myDevice: DeviceDcp): boolean =>
        myDevice.hasDcpType(DcpType.Control),
      )
      .find((myDevice: DeviceDcp): boolean => {
        const presence = (myDevice as DeviceDcp).options.dcpDevice
          .devicePresence;
        if (!presence) {
          throw new Error(`Cannot get presence=${myDevice.deviceId}`);
        }
        refName = lookupWebRtcReferenceFromLabel(
          presence.assembly,
          device.label,
        );
        return !!refName;
      });

    if (dcpDevice && refName) {
      console.log("Video stream is COMPOSITE (TDH)");
      let webRtcControls;
      const deviceList: DeviceMetadata[] = [];

      const reference =
        dcpDevice.options.dcpDevice.devicePresence.assembly?.references[
          refName
        ];
      const controllers =
        reference && reference.type === "webrtc-media-device"
          ? reference.controllers
          : [];
      controllers.forEach(
        (controller: IWebRtcMediaResourceController): void => {
          switch (controller.type) {
            case "local-webrtc":
              // assumes we won't get multiple webrtc controllers!

              // the virtual deviceId always appears first
              deviceList.unshift({
                deviceId: virtualDeviceId,
                component: virtualDeviceId,
              });

              webRtcControls = controller.controls.video;
              break;
            case "dcp-device":
              deviceList.push({
                deviceId: dcpDevice.deviceId,
                component: controller.component,
                services: controller.services,
              });
          }
        },
      );
      console.log("deviceList=", deviceList, webRtcControls);

      const connection = await sendCameraFunc(device, options?.stream, {
        ...params,
        devices: deviceList,
        capabilities: {
          ...params.capabilities,
          canPTZ: true,
        },
      });
      if (webRtcControls && connection) {
        await createVirtualOwner(
          virtualDeviceId,
          ptzOwners,
          context,
          tokenProvider,
          connection,
          webRtcControls,
        );
      }
      return connection;
    }
  }

  if (isPTZ) {
    // it's a PTZ camera
    console.log("Video stream has PTZ camera");

    const connection = await sendCameraFunc(device, options?.stream, {
      ...params,
      devices: [
        {
          deviceId: virtualDeviceId,
          component: virtualDeviceId,
        },
      ],
      capabilities: {
        ...params.capabilities,
        canPTZ: true,
      },
    });
    if (connection) {
      await createVirtualOwner(
        virtualDeviceId,
        ptzOwners,
        context,
        tokenProvider,
        connection,
      );
    }
    return connection;
  } else {
    // its not a DCP or a PTZ camera
    console.log("Video stream has no controls");

    return sendCameraFunc(device, options?.stream, {
      ...params,
      devices: [
        {
          deviceId: virtualDeviceId,
          component: virtualDeviceId,
        },
      ],
    });
  }
}

/**
 * The purpose of this context is to abstract Server management from the view
 */
export const ServerAdapterProvider = (props: ServerAdapterProviderProps) => {
  const { t } = useTranslation();
  const context = useSessionContext();
  const authenticationContext = useAuthenticatedUser();
  const environment = useEnvironment();

  const { user } = useUser(environment.apiUrl, environment.api.baseUrl, { suspense: false });
  const { organisation, session } = useSession(
    context.sessionParams?.sessionId,
  );
  const { members } = useMembers(organisation?.id);

  // use WeakMap so it gets deleted when the connection goes away
  const ptzOwners = React.useRef<Record<string, DeviceSessionLifeCycle>>({});

  // Local device state
  const [mic, setMic] = useState<DeviceLocalMicrophone | undefined>();
  const [audioVolume, setAudioVolume] = useState<number>(100);
  const [speaker, setSpeaker] = useState<DeviceLocalSpeaker | undefined>();
  const [errorDialog, setErrorDialog] = useState<ErrorDialogState>({
    show: false,
    text: "",
  });
  const { showSnackbar } = SnackbarContext.useSnackbarContext();

  const [participants, setParticipants] = useState<MediaParticipants>({
    local: {
      userDisplayName: user?.name ?? user?.email ?? "",
      userId: user?.profileId ?? 0,
      userUUID: user?.id ?? "",
      isConnected: false,
      isMuted: true,
      isActive: false,
      isLocal: true,
      streamId: "",
    },
  });

  const tokenRef = useRef<string | undefined>();
  useEffect(() => {
    tokenRef.current = authenticationContext?.token;
  }, [authenticationContext?.token]);

  // ServerAdapter vars set via callbacks
  // NB: DO NOT use these in the render loop,
  // theyre there to facilitate participants: MediaParticipants
  const [incomingVideos, setIncomingVideos] = useState<IncomingVideo[]>([]);
  const [outgoingVideos, setOutgoingVideos] = useState<OutgoingVideo[]>([]);
  const [audioParticipants, setAudioParticipants] = useState<AudioParticipants>(
    {},
  );

  const [ptzUsers, setPtzUsers] = React.useState<PtzUsersStore>({});

  useEffect(() => {
    if (!ServerAdapter.dcpEndpoint?.manager) {
      return;
    }

    const manager = ServerAdapter.dcpEndpoint?.manager;

    manager.on(
      "device:presence",
      (deviceId: string, presence: IDevicePresencePayload): void => {
        setPtzUsers((prevUsers: PtzUsersStore): PtzUsersStore => {
          const users = { ...prevUsers };

          handleDeviceUpdates({
            mediaSessionId: context.sessionParams?.mediaSessionId || "",
            ptzUsers: users,
            ptzOwners: ptzOwners.current,
            deviceId,
            presence,
            token: tokenRef.current || "",
            remoteDeviceManager: manager,
            homeBrokerConfig: context.serverParams?.dcpHomeBroker,
            sessionBrokerConfig: context.serverParams?.dcpSessionBroker,
          });

          return users;
        });
      },
    );
  }, [
    ServerAdapter.dcpEndpoint?.manager,
    context.serverParams?.dcpHomeBroker,
    context.serverParams?.dcpSessionBroker,
    context.sessionParams?.mediaSessionId,
  ]);

  const configureDefaultDevices = useCallback(() => {
    const defaultSpeaker =
      speaker ??
      (ServerAdapter.speakerMonitor?.getDefaultDeviceOrAny(
        false,
      ) as DeviceLocalSpeaker);
    defaultSpeaker && setSpeaker(defaultSpeaker);

    const defaultMic =
      mic ??
      (ServerAdapter.microphoneMonitor?.getDefaultDeviceOrAny(
        true,
      ) as DeviceLocalMicrophone);

    if (defaultMic) {
      setMic(defaultMic);
    } else {
      console.warn(
        "No audio input found, please check users Microphones, Cameras, and/or other devices",
      );
      showSnackbar({
        message: {
          body: t("common.components.snackbar.messages.noAudioInputFound"),
        },
        severity: "error",
      });
    }
  }, []);

  const isWatchRTCEnabled = useFlag(FeatureFlags.WATCH_RTC);
  const isGenerateFeedIdOnServer = useFlag(
    FeatureFlags.GENERATE_FEED_ID_ON_SERVER,
  );

  const SERVER_ADAPTER_START_TIMEOUT_MSECS = 15000;
  useTimeout(
    () => {
      console.error("Given up starting ServerAdapter");
      // force a visit to the CatchAllErrorPage
      context.socket?.emit("error", { message: "Cannot join session" });
    },
    ServerAdapter.isIdle() ? SERVER_ADAPTER_START_TIMEOUT_MSECS : 0,
  );

  useEffect(() => {
    (async (): Promise<void> => {
      if (
        !ServerAdapter.isIdle() ||
        !context.sessionParams ||
        !user ||
        !session ||
        !organisation ||
        !context.serverParams
      ) {
        console.debug(
          "Waiting for context before starting ServerAdapter",
          ServerAdapter.isIdle(),
          !!context.sessionParams,
          !!user,
          !!session,
          !!organisation,
          !!context.serverParams,
        );
        return;
      }

      await ServerAdapter.start({
        mediaSessionId: context.sessionParams.sessionId ?? "",
        profileId: user.profileId ?? 0,
        userUUID: user.id,
        serverParams: serverParamsToMediaServerParams(
          context.serverParams,
          isWatchRTCEnabled,
        ),
        statsMetadata: {
          endpoint: context.sessionParams.endpoint ?? "",
          organisationUuid: organisation?.id ?? "N/A",
          appointmentUuid: context.sessionParams?.sessionId ?? "",
          isSessionOwner: session.owner.id === user.id,
          // redacted email
          sessionOwnerEmail: user.email,
        },
        tokenProvider: () => tokenRef.current || "",
        codecs:
          environment.name === "development"
            ? localStorage.getItem("hackVideoCodecs") ?? "vp8,h264"
            : undefined,
        bitrate:
          environment.name === "development" &&
          localStorage.getItem("hackBitrate")
            ? Number(localStorage.getItem("hackBitrate"))
            : undefined,
        // we should use event listeners instead of callbacks
        incomingVideosCallback: setIncomingVideos,
        outgoingVideosCallback: setOutgoingVideos,
      });

      await ServerAdapter.joinAudio();
      if (ServerAdapter.audioConnection) {
        ServerAdapter.audioConnection.on(
          "participants",
          (myParticipants: AudioParticipants) => {
            setAudioParticipants(myParticipants);
          },
        );

        ServerAdapter.audioConnection.on(
          "participantleft",
          (streamId: string) => {
            setAudioParticipants((prevState) => {
              const newState = {
                ...prevState,
              };
              delete newState[streamId];
              return newState;
            });
          },
        );
        ServerAdapter.audioConnection.on(
          "participantisactive",
          (streamId: string, isActive: boolean): void => {
            setAudioParticipants((prevState) => {
              const newState = {
                ...prevState,
              };
              newState[streamId] = {
                ...prevState[streamId],
                isActive,
              };
              return newState;
            });
          },
        );
        ServerAdapter.audioConnection.on("closed", () => {
          setMic(undefined);
        });
      }

      ServerAdapter.microphoneMonitor?.on("added", configureDefaultDevices);
      ServerAdapter.microphoneMonitor?.on("removed", configureDefaultDevices);
      ServerAdapter.speakerMonitor?.on("added", configureDefaultDevices);
      ServerAdapter.speakerMonitor?.on("removed", configureDefaultDevices);
      configureDefaultDevices();
    })();
  }, [
    user,
    session,
    isWatchRTCEnabled,
    organisation,
    configureDefaultDevices,
    context,
  ]);

  const setLocalParticipant = () => {
    if (user) {
      setParticipants({
        ...participants,
        local: {
          ...participants.local,
          userDisplayName: user.name ?? user.email,
          userId: user.profileId || 0,
          userUUID: user.id,
        },
      });
    }
  };
  useEffect(setLocalParticipant, [user]);

  const consolidateMediaCallbackValuesIntoParticipants = () => {
    // retrieve the participants name from the appointment
    Object.keys(audioParticipants).forEach((streamId: string): void => {
      const participant = session?.participants.find(
        (p) => p.profileId === audioParticipants[streamId].userId,
      );
      const profile = members?.find((m) => m.id === participant?.id);

      if (profile && profile.name) {
        audioParticipants[streamId].userDisplayName = profile.name;
      }
    });

    setParticipants(
      MediaUtil.ConsolidateIntoParticipants(
        participants.local,
        audioParticipants,
      ),
    );
  };
  // I dont want participants.local being a dependency
  // if it is a dependency it renders every frame! Bad!
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(consolidateMediaCallbackValuesIntoParticipants, [
    audioParticipants,
  ]);

  const updateMic = () => {
    console.debug("Updating mic=", mic);
    async function updateConnection(): Promise<void> {
      if (
        ServerAdapter.isInitialised() &&
        ServerAdapter.audioConnection &&
        !!mic
      ) {
        console.info(
          { streamId: ServerAdapter.audioConnection.streamId },
          `UX Event: Select microphone ${mic.label}`,
        );
        const stream = await WebRTCUtil.GetAudioMedia(mic.deviceId);

        await ServerAdapter.audioConnection.send(stream);
      }
    }

    void updateConnection();
  };
  useEffect(updateMic, [mic]);

  const updateSpeaker = () => {
    if (
      ServerAdapter.isInitialised() &&
      ServerAdapter.audioConnection &&
      !!speaker?.deviceId
    ) {
      console.info(
        { streamId: ServerAdapter.audioConnection.streamId },
        `UX Event: Select speaker ${speaker.label}`,
      );
      ServerAdapter.audioConnection.setSpeaker(speaker.deviceId);
    }
  };
  useEffect(updateSpeaker, [speaker]);

  const updateVolume = () => {
    if (
      ServerAdapter.isInitialised() &&
      ServerAdapter.audioConnection &&
      !isNaN(audioVolume)
    ) {
      ServerAdapter.audioConnection.setVolume(audioVolume);
    }
  };
  useEffect(updateVolume, [audioVolume]);

  const derrivedAudioParticipants = participants
    ? [
        audioParticipantToParticipant(participants.local),
        // @ts-ignore
        ...audioParticipantsToParticipants(participants.remote),
      ]
    : [];

  const [derrivedVideos, setDerrivedVideos] = useState<Connection[]>([]);
  useEffect(() => {
    setDerrivedVideos(
      [...outgoingVideos, ...incomingVideos]
        .filter((v) => typeof v !== "undefined")
        .sort((a: Connection, b: Connection) => a.order.localeCompare(b.order)),
    );
  }, [incomingVideos, outgoingVideos]);

  const prevVideos = useRef<Connection[]>([]);

  useEffect(() => {
    if (
      !user?.profileId ||
      !context.socket ||
      !context.sessionParams?.mediaSessionId ||
      !context.serverParams?.dcpSessionBroker ||
      !ServerAdapter.dcpEndpoint?.manager
    ) {
      return;
    }

    setPtzUsers((prevUsers: PtzUsersStore): PtzUsersStore => {
      if (!ServerAdapter.dcpEndpoint?.manager) {
        return prevUsers;
      }

      const added = derrivedVideos.filter(
        (video: Connection): boolean => !prevVideos.current.includes(video),
      );
      const deleted = prevVideos.current.filter(
        (video: Connection): boolean => !derrivedVideos.includes(video),
      );

      console.log("Adding PTZ users");
      const users = { ...prevUsers };
      handlePtzUpdates(
        users,
        added,
        deleted,
        ServerAdapter.dcpEndpoint?.manager,
        user?.profileId,
        context.socket,
      );

      prevVideos.current = derrivedVideos;

      return users;
    });
  }, [
    derrivedVideos,
    user,
    context.socket,
    context.sessionParams,
    context.serverParams?.dcpSessionBroker,
  ]);

  const storeMuteState = (state: boolean, streamId?: string) => {
    const streamIdOut = streamId || "all";
    const details = {
      state,
      streamId: streamIdOut,
    };
    const muteState = state ? "mute" : "unmute";
    console.info({ streamId: streamIdOut }, `UX Event: ${muteState}`, details);
    context.socket?.sendSync(
      liveApiSockets.MediaSessionEventType.mute,
      details,
    );
  };

  const destroy = async (): Promise<void> => {
    await Promise.all(
      Object.values(ptzOwners.current).map(
        (lifecycle: DeviceSessionLifeCycle): Promise<void> => {
          return lifecycle.disconnect({ forced: true });
        },
      ),
    );
    return ServerAdapter.destroy();
  };

  const getAvailableIndex = (): number => {
    const potentiallyFilledConnectionsIndexes = Array<boolean>(4);

    Object.values(derrivedVideos).forEach((connection: Connection): void => {
      const { index } = MediaUtil.decodeStreamId(connection.streamId);
      potentiallyFilledConnectionsIndexes[index] = true;
    });

    // find the first unused element in potentiallyFilledConnectionsIndexes
    return potentiallyFilledConnectionsIndexes.findIndex(
      (elem) => typeof elem === "undefined",
    );
  };

  const getFeedParams = (
    device: Device,
    proposedFeedId?: string,
  ): Promise<liveApiSockets.GetFeedParamsReponse> => {
    /*ENDPOINT_CONFIG.video.maxPublishers*/
    if (derrivedVideos.length >= 4) {
      throw new exceptions.VideoCapacityExceededException(
        ExceptionCodes.VIDEO_CAPACITY_EXCEPTION_EXCEEDED,
      );
    }

    // check that we have established a stream already for this DCP device
    const existingDcpConnection = Object.values(outgoingVideos).find(
      (connection: Connection): boolean =>
        connection.devices[DeviceType.Dcp] === device,
    );
    if (existingDcpConnection) {
      console.warn(
        { deviceId: device.deviceId },
        "DCP device already in use - component=",
        (device as DeviceDcp).options.component,
      );
      throw new Error("DCP device in use");
    }

    if (!isGenerateFeedIdOnServer) {
      let index: number;
      let timestamp: number;
      let profileId: number;
      let type: number;

      if (proposedFeedId) {
        ({ type, profileId, index, timestamp } =
          MediaUtil.decodeStreamId(proposedFeedId));
      } else {
        type = device.mediaType;
        profileId = user?.profileId ?? 0;
        index = getAvailableIndex();
        timestamp = Date.now();
      }

      const feedId = MediaUtil.encodeStreamId({
        type,
        profileId,
        index,
        timestamp,
      });
      const order = MediaUtil.generateOrderFromStreamId(feedId);

      console.log("Generated feedId locally", feedId);

      return Promise.resolve({
        feedId,
        order,
      });
    }

    console.log("Generating feedId remotely");

    return context.socket?.sendAsync(
      liveApiSockets.MediaSessionEventType.getFeedParams,
      {
        deviceId: device.deviceId,
        //ordersInUse,
        mediaType: device.mediaType,
        feedId: proposedFeedId,
      },
    ) as Promise<liveApiSockets.GetFeedParamsReponse>;
  };

  return (
    <serverAdapterContext.Provider
      value={{
        isActive: ServerAdapter.isInitialised(),
        // Read only vars
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        mic,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        speaker,
        participants,
        audioParticipants: derrivedAudioParticipants,
        videos: derrivedVideos,
        // using the videos field to determine if a connection exists is not
        // possible when starting because the device is signalled available
        // before the video is propagated down.  So, we provide the hasVideo
        // hook direct into the ServerAdapter (TP-1079)
        hasVideo: (streamId: string): boolean =>
          ServerAdapter.hasConnection(streamId),
        errorDialog,
        // Getters/settings for state changes
        setErrorDialog,
        setMic,
        setSpeaker,
        audioVolume,
        setAudioVolume,
        sendMedia: async (
          device: Device,
          options: SendMediaOptionsInterface,
        ): Promise<OutgoingVideo> => {
          let connection: OutgoingVideo;
          try {
            switch (device.mediaType) {
              case FeedType.Camera:
                connection = await sendCamera(
                  device,
                  ServerAdapter.hostDeviceId,
                  ptzOwners.current,
                  context,
                  () => tokenRef.current || "",
                  async (...args): Promise<OutgoingVideo> => {
                    const { feedId, order } = await getFeedParams(
                      device,
                      options.streamId,
                    );
                    return ServerAdapter.createConnection(
                      feedId,
                      order,
                      ...args,
                      options.boundData,
                    );
                  },
                  ServerAdapter.dcpMonitor,
                  user,
                  options,
                );
                break;
              case FeedType.Screen:
                {
                  const virtualDeviceId = generateVirtualDeviceId(
                    user?.profileId ?? 0,
                    device.deviceId,
                  );
                  const { feedId, order } = await getFeedParams(
                    device,
                    options.streamId,
                  );
                  connection = await ServerAdapter.createConnection(
                    feedId,
                    order,
                    device,
                    (
                      options.boundData as {
                        maskHandler: VideoMaskHandler;
                      }
                    ).maskHandler.getStream() as MediaStream,
                    {
                      capabilities: { canMask: true },
                      devices: [
                        {
                          deviceId: virtualDeviceId,
                          component: virtualDeviceId,
                        },
                      ],
                    },
                    options.boundData,
                  );
                }
                break;
              default:
                throw new Error(`Unknown mediaType=${device.mediaType}`);
            }
          } catch (error) {
            // FYI: Socket.io server is unable to pass custom exceptions
            // on the client side any error thrown on socker.io server would appear as instance of Error class
            // and can only have the following properties: message, data:
            // like so:
            // // server-side
            // io.use((socket, next) => {
            //  const err = new Error("not authorized");
            //  err.data = { content: "Please retry later" }; // additional details
            //  next(err);
            // });
            // to avoid scaffolding new error on the fly we have decided to pass a custom exception code as message
            // and then on socket.io client recognise that comparing message matched custom exception code i.e.
            // if (VALID_EXCEPTIONS_CODES.includes(error.message)) {
            // source: https://socket.io/docs/v4/middlewares/
            if (VALID_EXCEPTIONS_CODES.includes(error.message)) {
              const errorCode = error.message;
              showSnackbar({
                message: { body: t(`common.api.errorCodes.${errorCode}`) },
                severity: "error",
              });
            }
            console.warn("Failed to send media stream - error=", error);
            throw error;
          }
          return connection;
        },
        muteAll: async (): Promise<void> => {
          await ServerAdapter.audioConnection?.muteAll();
          storeMuteState(true);
        },
        setMuteState: async (
          streamId: string,
          isMuted: boolean,
        ): Promise<void> => {
          await ServerAdapter.audioConnection?.setMuteState(streamId, isMuted);
          storeMuteState(isMuted, streamId);
        },
        // call the suicide function to prevent re-connections on this page
        destroy,
        ptzUsers,
        microphoneMonitor: ServerAdapter.microphoneMonitor,
        speakerMonitor: ServerAdapter.speakerMonitor,
        cameraMonitor: ServerAdapter.cameraMonitor,
      }}
    >
      {props.children}
    </serverAdapterContext.Provider>
  );
};
