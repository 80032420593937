import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogContent,
  CircularProgress,
  Link,
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { SnackbarContext } from "@proximie/components";
import _ from "lodash";
import { IP_SUPPORT_URL } from "../constants";
import { useFlavor } from "@proximie/flavorinator";
import { useTranslation } from "react-i18next";
import {
  TestList,
  TestResult,
  TestResultsList,
} from "../../../lib/models/TroubleShooterTests";

export const openSupportURL = () => window.open(IP_SUPPORT_URL, "_blank");

interface ErrorTextProps {
  code: string;
}

export const ErrorText = (props: ErrorTextProps): JSX.Element => {
  const ipAddressesLink = (
    <Link
      component="button"
      color="primary"
      onClick={openSupportURL}
      underline="always"
    >
      IP Addresses
    </Link>
  );
  const networkIssues: { [key: string]: string } = {
    [TestList.socketIO]:
      "Cannot reach communications server. Please check network and firewall to make sure websockets and required ipAddressesLink are not restricted",
    [TestList.audioServer]:
      "Cannot reach AUDIO media servers. Please check network and firewall to make sure required ipAddressesLink are not restricted",
    [TestList.audioMedia]:
      "Failed to start AUDIO media streams. Please check network and firewall to make sure UDP traffic to required ipAddressesLink are not restricted",
    [TestList.videoServer]:
      "Cannot reach VIDEO media servers. Please check network and firewall to make sure required ipAddressesLink are not restricted",
    [TestList.videoMedia]:
      "Failed to start VIDEO media streams. Please check network and firewall to make sure UDP traffic to required ipAddressesLink are not restricted",
  };
  const [before, after] = networkIssues[props.code].split("ipAddressesLink");
  return (
    <Box mb={1}>
      <Typography variant="body2" align={"justify"} gutterBottom>
        {before} {ipAddressesLink} {after}
      </Typography>
    </Box>
  );
};

interface TroubleshooterTipProps {
  title: string;
  body: string;
}
export const TroubleshooterTip = (
  props: TroubleshooterTipProps,
): JSX.Element => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        data-testid={"tip-summary"}
      >
        <Typography>{props.title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="body2" gutterBottom data-testid={"tip-body"}>
          {props.body}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

interface TroubleShooterNetworkResultProps {
  onclickRetry: () => void;
  onclickBack: () => void;
  isRunning: boolean;
  results: TestResultsList;
  retryCount: number;
}
const TroubleShooterNetworkResult = (
  props: TroubleShooterNetworkResultProps,
): JSX.Element => {
  const { t } = useTranslation();
  const { getConfiguration } = useFlavor();
  const appName = getConfiguration("appName");

  const [isHeaderDetailsOpen, setIsHeaderDetailsOpen] = useState(false);
  const { showSnackbar } = SnackbarContext.useSnackbarContext();
  const [snackRetryCounter, setSnackRetryCounter] = useState(0);
  useEffect(() => {
    if (
      !props.isRunning &&
      props.retryCount > 0 &&
      snackRetryCounter < props.retryCount
    ) {
      setSnackRetryCounter(props.retryCount);
      showSnackbar({
        message: { body: "Unable to join. Try a different task" },
        severity: "error",
        autoHideDuration: 4000,
      });
    }
  }, [props.isRunning, props.retryCount, showSnackbar, snackRetryCounter]);

  const theme = useTheme();
  return (
    <Dialog
      open={true}
      data-cy={"troubleshooter-dialog"}
      data-testid={"troubleshooter-dialog"}
    >
      <DialogContent>
        <Box
          textAlign={"center"}
          px={8}
          pt={4}
          pb={2}
          mb={8}
          bgcolor={theme.palette.background.default}
        >
          <Box mb={3}>
            <Typography variant="h5" component={"div"}>
              Your IT network is preventing you from joining the session
            </Typography>
          </Box>
          <Box px={4} mb={2}>
            <Typography variant="subtitle1" component={"div"}>
              Although you are connected to the internet, your IT network
              security may be an issue.
            </Typography>
          </Box>

          {isHeaderDetailsOpen && (
            <Box mb={2} data-testid="error-details-list">
              {Object.entries(props.results)
                .filter((res) => res[1] === TestResult.Fail)
                .map((result: [string, number], index: number) => (
                  <ErrorText key={index} code={result[0]} />
                ))}
            </Box>
          )}

          <Box mb={1}>
            <Link
              component="button"
              color="primary"
              data-cy={"troubleshooter-back-button"}
              onClick={() => setIsHeaderDetailsOpen(!isHeaderDetailsOpen)}
              underline="always"
              data-testid="details-toggle"
            >
              {isHeaderDetailsOpen ? "Hide details" : "Show details"}
            </Link>
          </Box>
        </Box>

        <Box textAlign={"center"} mt={2} mb={5}>
          <Box mb={1}>
            <Typography variant="h5" component={"div"}>
              Need some help?
            </Typography>
          </Box>
          <Box px={14}>
            <Typography variant="subtitle1" component={"div"}>
              Complete one of the below tasks then try to join the session
            </Typography>
          </Box>
        </Box>

        <TroubleshooterTip
          title={"1. Connect to a different network"}
          body={t("liveSession.troubleShooter.tip1.body", { appName })}
        />

        <TroubleshooterTip
          title={"2. Use your mobile as a Wi-Fi Hotspot"}
          body={`Use your mobile phone as a Wi-Fi hotspot to connect this device to the internet. Your mobile data will be used during the session`}
        />

        <TroubleshooterTip
          title={"3. Call our Customer Support"}
          body={t("liveSession.troubleShooter.tip3.body")}
        />

        <Box textAlign={"center"} mt={6} height={25}>
          {!props.isRunning && props.retryCount > 0 && (
            <Typography variant="subtitle1" component={"div"} color={"error"}>
              Unable to join. Try different task.
            </Typography>
          )}
        </Box>

        {props.results.socketIO !== TestResult.Fail && (
          <Box my={2} textAlign={"center"} mt={3}>
            <Button
              size="large"
              color="secondary"
              variant="contained"
              data-cy={"troubleshooter-continue-button"}
              onClick={props.onclickRetry}
              disabled={props.isRunning}
              data-testid="retry-join-button"
            >
              <Box width={250} height={25}>
                {props.isRunning && (
                  <Box pt={0.4} display={"inline"} px={2}>
                    <CircularProgress
                      color={"inherit"}
                      size={18}
                      data-testid="test-running-animation"
                    />
                  </Box>
                )}
                {props.isRunning ? "Attempting to join" : "Try joining now"}
              </Box>
            </Button>
          </Box>
        )}

        <Box my={4} textAlign={"center"}>
          <Link
            component="button"
            color="secondary"
            data-cy={"troubleshooter-back-button"}
            onClick={props.onclickBack}
            underline="always"
            data-testid="return-button"
          >
            Back to Dashboard
          </Link>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default TroubleShooterNetworkResult;
