import { LeafMonitor } from "../Monitor";
import axios from "axios";

export type TimeDeviationReport = {
  deviation: number;
};

const getServerTimeEndpoint = (apiUrl: string) => `${apiUrl}/api/v2/time`;

export class TimeDeviationMonitor extends LeafMonitor<TimeDeviationReport> {
  constructor() {
    super({ intervalMs: 20000 });
  }

  async run(): Promise<TimeDeviationReport> {
    try {
      const beforeTime = Date.now();

      const serverTimeEndpoint = getServerTimeEndpoint(
        this.context?.environmentContext?.apiUrl ?? "",
      );
      const response = await axios.get(serverTimeEndpoint);
      const serverTime = response.data as { time: number };

      const afterTime = Date.now();

      const rtt = afterTime - beforeTime;
      const accurateComparisonTime = afterTime - rtt / 2;
      const deviation = serverTime.time - accurateComparisonTime;

      return { deviation };
    } catch {
      console.error(`Could not reach server time endpoint!`);
    }

    return { deviation: Infinity };
  }
}
