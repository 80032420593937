import useSWR, { SWRConfiguration } from "swr";
import axios from "axios";
import { augmentObjectWithSequentialID } from "../common/augmentObjectWithLegacyID";
import { user } from "@proximie/api";

export const useUser = (
  apiUrl: string,
  apiBaseUrl: string,
  opts?: SWRConfiguration,
) => {
  const getUser: () => Promise<user.User> = async () => {
    const user = (await axios.get<user.User>(`${apiUrl}/api/v2/users/me`)).data;
    const [userWithLegacyId] = await augmentObjectWithSequentialID<user.User>(
      [user],
      apiBaseUrl,
    );
    return userWithLegacyId;
  };

  const { data, error } = useSWR<user.User>(`user`, getUser, opts);

  return {
    user: data,
    isUserLoading: !error && !data,
    error: error,
  };
};
