import AudioParticipant, { AudioParticipants } from "./models/AudioParticipant";
import Participants from "./models/Participants";
import { FeedType } from "@proximie/common";

const STREAM_ID_SEPARATOR = "-";
const STREAM_ID_REGEX = /^\d+-\d+-\d+-\d+$/;

export function encodeStreamId(streamIdParts: {
  type: FeedType;
  profileId: number;
  index?: number;
  timestamp?: number;
}): string {
  return `${streamIdParts.type}${STREAM_ID_SEPARATOR}${
    streamIdParts.profileId
  }${STREAM_ID_SEPARATOR}${streamIdParts.index || 0}${STREAM_ID_SEPARATOR}${
    streamIdParts.timestamp || Date.now()
  }`;
}

export function decodeStreamId(streamId: string): {
  type: FeedType;
  profileId: number;
  index: number;
  timestamp: number;
} {
  const parts = streamId.split(STREAM_ID_SEPARATOR);

  return {
    type: Number(parts[0]),
    profileId: Number(parts[1]),
    index: Number(parts[2]),
    timestamp: Number(parts[3]),
  };
}

export function validateStreamId(streamId: string): boolean {
  return STREAM_ID_REGEX.test(streamId);
}

// turn the components of a streamId into a order string suitable for sorting
// (required only for backwards compatibility)
export function generateOrderFromStreamId(streamId: string): string {
  const { index, profileId, timestamp } = decodeStreamId(streamId);
  return `${index.toString().padStart(4, "0")}:${profileId
    .toString()
    .padStart(10, "0")}:${timestamp.toString().padStart(20, "0")}`;
}

export const ConsolidateIntoParticipants = (
  local: AudioParticipant,
  participants: AudioParticipants,
): Participants => {
  const participantsArray: AudioParticipant[] = Object.values(participants);

  // If the local user was found in media server participants, update
  const localUserFromMediaServer = participantsArray.find(
    (participant) => participant.isLocal,
  );

  if (localUserFromMediaServer) {
    local.metadata = localUserFromMediaServer.metadata;
    local.streamId = localUserFromMediaServer.streamId;
    local.isConnected = localUserFromMediaServer.isConnected;
    local.isMuted = localUserFromMediaServer.isMuted;
    local.isActive = localUserFromMediaServer.isActive;
  }

  const remote: AudioParticipant[] = participantsArray.filter(
    (participant: AudioParticipant): boolean =>
      participant.streamId !== local.streamId,
  );

  return { local, remote };
};

export default {
  ConsolidateIntoParticipants,
  encodeStreamId,
  decodeStreamId,
  validateStreamId,
  generateOrderFromStreamId,
};
