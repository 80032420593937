/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { SnackbarContext, useEmbeddedClient } from "@proximie/components";
import { liveApiSockets } from "@proximie/dataregion-api";
import { useFlag, useUnleashContext } from "@proximie/feature-flag-react-sdk";
import { UsageTrackerEvents, useUsageTracker } from "@proximie/tracking";
import {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import Connection from "../../../lib/ServerAdapter/Connections/Connection";
import WebRTCUtil from "../../../lib/WebRTCUtil";
import { ChatMessage } from "../../components/Chat/Chat";
import { FeatureFlags } from "../../components/constants";
import { useEnvironment } from "../../hooks/useEnvironment";
import { useMembers } from "../../hooks/useMembers";
import { usePermissions } from "../../hooks/usePermissions";
import { useSession } from "../../hooks/useSession";
import { useUser } from "../../hooks/useUser";
import { useUserRole } from "../../hooks/useUserRole";
import ServerParams from "../../models/ServerParams";
import SessionContext, {
  FeedSpotlight,
  SessionFeedView,
  ShowForPermissionProps,
} from "../../models/SessionContext";
import SessionParams from "../../models/SessionParams";
import { getSessionParamsFromURLQueryOrNull } from "../../utils";
import { checkPermission } from "../../utils/checkPermission";
import SocketIoClientWrapper from "../../wrappers/SocketIOClientWrapper/SocketIOClientWrapper";

export const sessionContext = createContext<SessionContext>({
  endSession: undefined,
  sessionParams: null,
  socket: undefined,
  serverParams: null,
  chats: undefined,
  leaveSession: undefined,
  permissions: undefined,
  submitChat: undefined,
  session: undefined,
  members: undefined,
  organisation: undefined,
  user: undefined,
  inviteParticipant: () => undefined,
  ShowForPermission: (() => undefined) as never,
  checkMyPermission: (() => undefined) as never,
  role: undefined,
  spotlight: {
    spotlightedFeed: null,
    requestSpotlightedFeeds: () => undefined,
    setSpotlightedFeed: (_streamId) => undefined,
  },
  view: SessionFeedView.GRID_VIEW,
  setView: (_view) => undefined,
  setSessionVideos: (_videos) => undefined,
});
export const useSessionContext = () => useContext(sessionContext);

interface SessionProviderProps {
  children: ReactElement;
  socketIoClientWrapper: SocketIoClientWrapper;
}

export const SessionProvider = (props: SessionProviderProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { isEmbedded, setData } = useEmbeddedClient();
  const environment = useEnvironment();

  const socket = props.socketIoClientWrapper;
  const [serverParams, setServerParams] = useState<ServerParams | null>(null);
  const [chats, setChats] = useState<ChatMessage[]>([]);
  const [sessionParams] = useState<SessionParams | null>(
    getSessionParamsFromURLQueryOrNull(),
  );

  const { organisation, session, inviteParticipant, endSession } = useSession(
    sessionParams?.sessionId,
  );
  const { members } = useMembers(organisation?.id);
  const { user } = useUser(environment.apiUrl, environment.api.baseUrl, {
    suspense: false,
  });
  const { role } = useUserRole(sessionParams?.sessionId, user?.id, {
    suspense: false,
    refreshInterval: 10 * 1000,
  });
  const permissions = usePermissions(session, user?.id);

  const updateContext = useUnleashContext();
  const { tracker } = useUsageTracker();

  useEffect(() => {
    updateContext({
      userId: user?.id ?? "",
      properties: {
        appointmentId: session?.id ?? "",
        organisationId: organisation?.id ?? "",
      },
    });
  }, [user?.id, updateContext, session, organisation]);

  const isSessionOwnerTransferEnabled = useFlag(
    FeatureFlags.SESSION_OWNER_TRANSFER_MILESTONE_2,
  );

  const isSpotlightEnabled = useFlag(FeatureFlags.SPOTLIGHT);

  const { showSnackbar } = SnackbarContext.useSnackbarContext();

  useEffect(() => {
    if (sessionParams) {
      console.debug("Socket: Initialising");
      props.socketIoClientWrapper.init(
        sessionParams,
        setServerParams,
        environment.domain,
        environment.name,
      );
      console.debug("Socket: Joining");

      if (isEmbedded && sessionParams.sessionId) {
        void setData?.({ sessionId: sessionParams.sessionId });
      }

      const capabilities: liveApiSockets.UserCapabilities = {
        userAgent: navigator.userAgent,
        language: navigator.language,
        isTouchSupport: navigator.maxTouchPoints > 0,
        screenSize: {
          width: window.screen.width,
          height: window.screen.height,
        },
        isCookieEnabled: navigator.cookieEnabled,
        logicCores: navigator.hardwareConcurrency,
        //eslint-disable-next-line @typescript-eslint/no-explicit-any
        connectionDownlinkMbps: ((navigator as any).connection as any)
          ?.downlink,
        //eslint-disable-next-line @typescript-eslint/no-explicit-any
        connectionType: ((navigator as any).connection as any)?.effectiveType,
        //eslint-disable-next-line @typescript-eslint/no-explicit-any
        memoryGb: (navigator as any).deviceMemory,
        gpu: WebRTCUtil.GetVideoCardInfo(),
      };

      props.socketIoClientWrapper.joinSession(sessionParams, capabilities);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionParams, props.socketIoClientWrapper, setData]);

  useEffect(() => {
    if (sessionParams === null && location.pathname !== "/error/url") {
      console.debug(
        "/session/start loaded without params, routing to error/url",
      );
      navigate("/error/url");
    } else if (location.pathname === "/session/start") {
      navigate(`/session/in-progress${location.search}`);
    }
  });

  useEffect(() => {
    if (!socket) {
      return;
    }

    const endSessionFunc = () => {
      const navigateToEndSessionPage = new Event("endSession");
      window.dispatchEvent(navigateToEndSessionPage);
    };

    socket.on("ended", endSessionFunc);
    socket.on("error", (error: Error) => {
      console.warn("Failed to connect websocket - error=", error);
    });

    socket.on(liveApiSockets.MediaSessionEventType.endSession, endSessionFunc);
  }, [socket]);

  useEffect(() => {
    const handleIncomingMessage = (
      detail: any,
      profileId: number,
      timestamp: number,
      sequenceNumber: number,
    ) => {
      setChats((chats) => [
        ...chats,
        {
          type: "",
          message: detail.message,
          profileName: detail.author,
          mediaSessionId: "",
          date: new Date(timestamp),
          sequenceNumber,
          isAuthor: user?.profileId === profileId,
        },
      ]);
    };

    if (socket && user) {
      socket.on(
        liveApiSockets.MediaSessionEventType.chat,
        handleIncomingMessage,
      );

      return () => {
        socket.off(
          liveApiSockets.MediaSessionEventType.chat,
          handleIncomingMessage,
        );
      };
    }
  }, [user, socket]);

  const leaveSession = () => props.socketIoClientWrapper.destroy();
  const submitChat = (message: string) =>
    props.socketIoClientWrapper.sendChatMessage(message);

  const ShowForPermission = ({
    permission,
    children,
    invert = false,
    additionalPredicate = (isAllowed) => isAllowed,
  }: ShowForPermissionProps) => {
    const permissionGuard = checkPermission(
      role,
      permission,
      isSessionOwnerTransferEnabled,
      isSpotlightEnabled,
    );
    const isAllowed = invert !== permissionGuard;

    return additionalPredicate(isAllowed) ? children : null;
  };

  const checkMyPermission = (permission: string | string[]) => {
    return checkPermission(
      role,
      permission,
      isSessionOwnerTransferEnabled,
      isSpotlightEnabled,
    );
  };

  // list of videos  in the session
  const [sessionVideos, setSessionVideos] = useState<Connection[]>([]);

  // this is the raw spotlighted feed coming from the ws
  const [rawSpotlightedFeed, setRawSpotlightedFeed] =
    useState<FeedSpotlight | null>(null);

  // this is the spotlighted feed that will be used in the FE after checking that it is valid
  const [spotlightedFeed, setSpotlightedFeed] = useState<FeedSpotlight | null>(
    null,
  );

  const [view, setView] = useState<SessionFeedView>(SessionFeedView.GRID_VIEW);

  // this useEffect handles the setting of the actual spotlighted feed after doing the checks
  // later on when we have a better understanding of what we need and where
  // we might break thi useEffect into multiple ones
  // and maybe add notification logic if needed here
  useEffect(() => {
    if (
      !sessionVideos.some((v) => v.streamId === rawSpotlightedFeed?.streamId)
    ) {
      if (spotlightedFeed?.streamId != null) {
        setSpotlightedFeed(null);
        setView(SessionFeedView.GRID_VIEW);
      }
    } else {
      const newSpotlightedFeed =
        rawSpotlightedFeed?.streamId == null ? null : rawSpotlightedFeed;
      if (newSpotlightedFeed?.streamId !== spotlightedFeed?.streamId) {
        setSpotlightedFeed(newSpotlightedFeed);
        setView(
          newSpotlightedFeed
            ? SessionFeedView.SPOTLIGHT_VIEW
            : SessionFeedView.GRID_VIEW,
        );
      }
    }
  }, [rawSpotlightedFeed, sessionVideos, spotlightedFeed, view]);

  const handleSetView = (view: SessionFeedView) => {
    setView(view);
  };

  const refreshSpotlightedFeed = useCallback(async () => {
    if (!(socket && isSpotlightEnabled)) return;
    const spotlightedFeedsHistory = (await socket.sendAsync(
      liveApiSockets.MediaSessionEventType.getSpotlightHistory,
      {},
    )) as liveApiSockets.SpotlightEvents;
    if (!spotlightedFeedsHistory) {
      return;
    }
    const [lastSpotlightEvent] = spotlightedFeedsHistory.slice(-1);
    setRawSpotlightedFeed(
      lastSpotlightEvent?.streamId == null
        ? null
        : {
            streamId: lastSpotlightEvent.streamId,
            spotlightedBy: { profileUuid: lastSpotlightEvent.profileUuid },
          },
    );
  }, [isSpotlightEnabled, socket]);

  // GET SPOTLIGHT ON MOUNT
  useEffect(() => {
    refreshSpotlightedFeed();
  }, [refreshSpotlightedFeed]);

  const spotlightFeed = async (streamId: string | null): Promise<void> => {
    if (!(socket && isSpotlightEnabled)) return;
    try {
      const prevSpotlightedFeed = spotlightedFeed;
      await socket.sendAsync(
        liveApiSockets.MediaSessionEventType.spotlightStream,
        {
          streamId,
        },
      );
      await refreshSpotlightedFeed();
      if (streamId == null) {
        showSnackbar({
          message: {
            body: t("common.components.snackbar.messages.spotlightRemoved"),
          },
          severity: "success",
        });
        tracker.track(UsageTrackerEvents.FeedSpotlightRemoved, {
          streamId: prevSpotlightedFeed?.streamId ?? "",
          spotlightedBy: prevSpotlightedFeed?.spotlightedBy.profileUuid ?? "",
        });
      } else {
        tracker.track(UsageTrackerEvents.FeedSpotlighted, {
          streamId,
          spotlightedBy: user?.id ?? "",
        });
      }
    } catch {
      console.error("error");
    }
  };

  useEffect(() => {
    const handleSpotlightFeedChange = (_message: any) => {
      refreshSpotlightedFeed();
    };

    if (socket && user) {
      socket.on(
        liveApiSockets.MediaSessionEventType.spotlightStream,
        handleSpotlightFeedChange,
      );

      return () => {
        socket.off(
          liveApiSockets.MediaSessionEventType.spotlightStream,
          handleSpotlightFeedChange,
        );
      };
    }
  }, [user, refreshSpotlightedFeed, socket]);

  return (
    <sessionContext.Provider
      value={{
        serverParams,
        sessionParams,
        chats: chats,
        permissions,
        session,
        members,
        organisation,
        inviteParticipant,
        endSession: endSession,
        leaveSession: leaveSession,
        submitChat: submitChat,
        socket: props.socketIoClientWrapper,
        ShowForPermission,
        checkMyPermission,
        role,
        user,
        spotlight: {
          spotlightedFeed,
          setSpotlightedFeed: spotlightFeed,
          requestSpotlightedFeeds: refreshSpotlightedFeed,
        },
        setSessionVideos,
        view,
        setView: handleSetView,
      }}
    >
      {props.children}
    </sessionContext.Provider>
  );
};
