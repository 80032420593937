import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, useTheme } from "@mui/material";
import {
  ElipMask,
  MaskHandlerEvents,
  RectMask,
  Shapes,
  VideoMaskHandler,
} from "../../../lib/CanvasUtils";
import { fabric } from "fabric";
import { IEvent } from "fabric/fabric-impl";
import { FabricCanvasContainerStyled } from "./MaskedVideo.style";

export interface VideoMaskingEditorProps {
  maskHandler: VideoMaskHandler;
}

interface ExtendedFabricObject extends fabric.Object {
  rx: number;
  ry: number;
  type: "ellipse" | "rect";
  zoomX: number;
  zoomY: number;
  width: number;
  height: number;
  scaleX: number;
  scaleY: number;
  left: number;
  top: number;
  angle: number;
}

interface ExtendedIEvent extends IEvent {
  transform: {
    corner: string;
    original: fabric.Object;
    originX: string;
    originY: string;
    width: number;
    action: string;
  };
}

const eraseCursor = `url('data:image/svg+xml;utf8,<svg fill="%23ffffff" width="15px" height="15px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 15 15.8"><path d="M14.4,12.6h-4c0.1-0.1,0.1-0.1,0.1-0.2c1.3-1.3,2.6-2.6,3.9-3.8c0.7-0.5,0.8-1.5,0.2-2.2c-0.1-0.1-0.2-0.2-0.2-0.2 c-1.5-1.4-2.9-2.9-4.4-4.4C9.5,1.3,8.6,1.1,8,1.7C7.9,1.7,7.9,1.8,7.8,1.9L0.5,9.1c-0.6,0.5-0.8,1.4-0.2,2c0.1,0.1,0.2,0.2,0.2,0.2 c0.7,0.8,1.6,1.7,2.3,2.5c0.4,0.4,0.9,0.6,1.5,0.6c3.3,0,6.6,0,9.9,0c0.1,0,0.2,0,0.3,0c0.3,0,0.5-0.2,0.4-0.5c-0.1-0.3,0-0.7,0-1 S14.9,12.6,14.4,12.6z M9.5,10.8c-0.6,0.5-1.1,1.1-1.7,1.7c-0.1,0.1-0.2,0.2-0.3,0.2c-1,0-2,0-3,0c-0.2,0-0.3-0.1-0.4-0.2l-2-2 c-0.1-0.1-0.2-0.2,0-0.3l3.5-3.4c0,0,0,0,0.1-0.1C7,7.9,8.2,9.2,9.5,10.5C9.7,10.6,9.7,10.6,9.5,10.8z"/></svg>') 10 10, not-allowed`;

const MaskedVideo = ({ maskHandler }: VideoMaskingEditorProps) => {
  const { colors, palette } = useTheme();

  const MediaRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const fabricCanvasRef = useRef<fabric.Canvas>();
  const [videoScale, setVideoScale] = useState(1);
  const [isEraseMode, setIsEraseMode] = useState<boolean | null>(false);

  const [isMouseDown, setIsMouseDown] = useState(false);
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
  const [aspectRatio, setAspectRation] = useState("16 / 9");

  const [selectedMaskingShape, setSelectedMaskingShape] =
    useState<Shapes | null>(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getMasksFromShapes = (): (RectMask | ElipMask)[] => {
    if (!fabricCanvasRef.current) return [];
    fabricCanvasRef.current.renderAll();

    const allShapes =
      fabricCanvasRef.current.getObjects() as ExtendedFabricObject[];

    const masks: Array<RectMask | ElipMask> = [];
    allShapes.forEach((shape: ExtendedFabricObject) => {
      const ch = fabricCanvasRef.current?.height ?? 0;
      const cw = fabricCanvasRef.current?.width ?? 0;
      if (shape.type === "ellipse") {
        masks.push({
          shape: Shapes.Elip,
          x: Math.round(shape.left) / cw,
          y: Math.round(shape.top) / ch,
          rx: Math.round(shape.rx) / cw,
          ry: Math.round(shape.ry) / ch,
          a: shape.angle,
        });
      } else if (shape.type === "rect") {
        masks.push({
          shape: Shapes.Rect,
          x: Math.round(shape.left) / cw,
          y: Math.round(shape.top) / ch,
          w: Math.round(shape.width) / cw,
          h: Math.round(shape.height) / ch,
          a: shape.angle,
        });
      }
    });
    return masks;
  };

  const setShapesFromMasks = useCallback(() => {
    if (!fabricCanvasRef.current) return;

    fabricCanvasRef.current
      .getObjects()
      .forEach((shape) => fabricCanvasRef.current?.remove(shape));

    const ch = fabricCanvasRef.current?.height ?? 0;
    const cw = fabricCanvasRef.current?.width ?? 0;

    const masks = maskHandler.getMasks();
    masks.forEach((mask) => {
      let newShape;
      if (mask.shape === Shapes.Rect) {
        newShape = new fabric.Rect({
          width: mask.w * cw,
          height: mask.h * ch,
          left: mask.x * cw,
          top: mask.y * ch,
          fill: "",
          angle: mask.a,
          originX: "center",
          originY: "center",
          transparentCorners: false,
          cornerColor: "white",
          cornerStrokeColor: colors.Secondary600,
          borderColor: colors.Secondary600,
        });
      } else if (mask.shape === Shapes.Elip) {
        newShape = new fabric.Ellipse({
          left: mask.x * cw,
          top: mask.y * ch,
          rx: mask.rx * cw,
          ry: mask.ry * ch,
          angle: mask.a,
          fill: "",
          originX: "center",
          originY: "center",
          transparentCorners: false,
          cornerColor: "white",
          cornerStrokeColor: colors.Secondary600,
          borderColor: colors.Secondary600,
        });
      }
      if (newShape && fabricCanvasRef.current) {
        fabricCanvasRef.current.add(newShape);
        fabricCanvasRef.current.renderAll();
      }
    });
  }, [maskHandler]);

  const updateShapeToScale = (shape: ExtendedFabricObject) => {
    const { width, height, scaleX, scaleY, angle, left, top, rx, ry } = shape;
    if (shape.type === "rect") {
      shape.set({
        width: width * scaleX,
        height: height * scaleY,
        left: left,
        top: top,
        angle: angle,
        originX: "center",
        originY: "center",
        scaleX: 1,
        scaleY: 1,
      });
    } else if (shape.type === "ellipse") {
      shape.set({
        left: left,
        top: top,
        rx: rx * scaleX,
        ry: ry * scaleY,
        angle: angle,
        originX: "center",
        originY: "center",
        scaleX: 1,
        scaleY: 1,
      });
    }
  };

  /* Mouse Events */
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const setupMouseEvents = useCallback(() => {
    const mousedown = (event: IEvent) => {
      if (!fabricCanvasRef.current || selectedMaskingShape == null) return;

      const { x, y } = fabricCanvasRef.current.getPointer(event.e);
      setIsMouseDown(true);
      setMousePos({ x, y });

      let newShape;
      if (selectedMaskingShape === Shapes.Rect) {
        newShape = new fabric.Rect({
          width: 0,
          height: 0,
          left: x,
          top: y,
          fill: "",
          strokeDashArray: [5, 5],
          stroke: "white",
          transparentCorners: false,
          cornerColor: "white",
          cornerStrokeColor: colors.Secondary600,
          borderColor: colors.Secondary600,
        });
      } else if (selectedMaskingShape === Shapes.Elip) {
        newShape = new fabric.Ellipse({
          left: x,
          top: y,
          rx: 0,
          ry: 0,
          angle: 0,
          fill: "",
          strokeDashArray: [5, 5],
          stroke: "white",
          transparentCorners: false,
          cornerColor: "white",
          cornerStrokeColor: colors.Secondary600,
          borderColor: colors.Secondary600,
        });
      }

      if (newShape) {
        fabricCanvasRef.current.add(newShape);
        fabricCanvasRef.current.renderAll();
        fabricCanvasRef.current.setActiveObject(newShape);
      }
    };
    const mousemove = (event: IEvent) => {
      if (
        !isMouseDown ||
        !fabricCanvasRef.current ||
        selectedMaskingShape == null
      ) {
        return false;
      }

      const mouse = fabricCanvasRef.current.getPointer(event.e);
      const newShape =
        fabricCanvasRef.current.getActiveObject() as ExtendedFabricObject;

      const w = Math.abs(mouse.x - mousePos.x);
      const h = Math.abs(mouse.y - mousePos.y);
      const x = mouse.x - mousePos.x < 0 ? mouse.x : mousePos.x;
      const y = mouse.y - mousePos.y < 0 ? mouse.y : mousePos.y;

      if (selectedMaskingShape === Shapes.Rect) {
        newShape.set({ width: w, height: h, left: x, top: y });
      } else if (selectedMaskingShape === Shapes.Elip) {
        newShape.set({ rx: w / 2, ry: h / 2, left: x, top: y });
      }

      fabricCanvasRef.current.renderAll();
    };
    const mouseup = (event: IEvent) => {
      if (!fabricCanvasRef.current || selectedMaskingShape == null) {
        return false;
      }
      if (isMouseDown) {
        setIsMouseDown(false);
      }

      const newShape =
        fabricCanvasRef.current.getActiveObject() as ExtendedFabricObject;

      //switch to center origin
      const mouse = fabricCanvasRef.current.getPointer(event.e);
      const x = (mouse.x + mousePos.x) / 2;
      const y = (mouse.y + mousePos.y) / 2;

      newShape.left = x;
      newShape.top = y;

      newShape.originX = "center";
      newShape.originY = "center";

      fabricCanvasRef.current.renderAll();
      maskHandler.setSelectedMaskingShape(null);

      //update mask handler
      const masks = getMasksFromShapes();
      maskHandler.setMasks(masks);
      maskHandler.addHistory(masks);
    };
    const objectModified = (event: IEvent) => {
      if (!fabricCanvasRef.current) {
        return false;
      }

      if (
        (event as ExtendedIEvent).transform.action.includes("scale") &&
        event.target
      ) {
        updateShapeToScale(event.target as ExtendedFabricObject);
      }

      //update mask handler
      const masks = getMasksFromShapes();
      maskHandler.setMasks(masks);
      maskHandler.addHistory(masks);
    };
    const selectionCreated = () => {
      maskHandler.setIsObjectSelected(true);
    };
    const selectionCleared = () => {
      maskHandler.setIsObjectSelected(false);
    };
    const objectBeingModified = (event: IEvent) => {
      if (!fabricCanvasRef.current) {
        return false;
      }

      if (
        (event as ExtendedIEvent).transform.action.includes("scale") &&
        event.target
      ) {
        updateShapeToScale(event.target as ExtendedFabricObject);
      }

      //update mask handler
      const masks = getMasksFromShapes();
      maskHandler.setMasks(masks);
    };

    if (!fabricCanvasRef.current) {
      setTimeout(() => {
        setupMouseEvents();
      }, 500);
      return;
    }
    fabricCanvasRef.current.on("mouse:down", mousedown);
    fabricCanvasRef.current.on("mouse:move", mousemove);
    fabricCanvasRef.current.on("mouse:up", mouseup);
    fabricCanvasRef.current.on("object:modified", objectModified);
    fabricCanvasRef.current.on("selection:created", selectionCreated);
    fabricCanvasRef.current.on("selection:cleared", selectionCleared);

    fabricCanvasRef.current.on("object:scaling", objectBeingModified);
    fabricCanvasRef.current.on("object:resizing", objectBeingModified);
    fabricCanvasRef.current.on("object:moving", objectBeingModified);
    fabricCanvasRef.current.on("object:rotating", objectBeingModified);
    fabricCanvasRef.current.on("mouse:over", (e) => {
      if (e.target != null) {
        if (isEraseMode) {
          e.target.hoverCursor = eraseCursor;
          fabricCanvasRef.current?.renderAll();
        } else {
          e.target.hoverCursor =
            selectedMaskingShape == null ? "pointer" : "crosshair";
        }
      }
    });
  }, [
    isMouseDown,
    maskHandler,
    mousePos.x,
    mousePos.y,
    selectedMaskingShape,
    isEraseMode,
  ]);

  const removeMouseEvents = () => {
    if (fabricCanvasRef.current) {
      fabricCanvasRef.current.off("mouse:move");
      fabricCanvasRef.current.off("mouse:down");
      fabricCanvasRef.current.off("mouse:up");
      fabricCanvasRef.current.off("mouse:over");
      fabricCanvasRef.current.off("object:modified");
      fabricCanvasRef.current.off("selection:created");
      fabricCanvasRef.current.off("selection:cleared");

      fabricCanvasRef.current.off("object:scaling");
      fabricCanvasRef.current.off("object:resizing");
      fabricCanvasRef.current.off("object:moving");
      fabricCanvasRef.current.off("object:rotating");
    }
  };

  useEffect(() => {
    setupMouseEvents();
    return () => {
      removeMouseEvents();
    };
  }, [setupMouseEvents]);

  const calculateScale = () => {
    if (MediaRef.current) {
      const { videoHeight, offsetHeight, videoWidth } = MediaRef.current ?? {
        videoHeight: 720,
        offsetHeight: 720,
        videoWidth: 1280,
      };
      const newScale = !isNaN(offsetHeight / videoHeight)
        ? offsetHeight / videoHeight
        : 1;
      setVideoScale(newScale);

      setAspectRation(`${videoWidth} / ${videoHeight}`);
    }
  };

  useEffect(() => {
    if (fabricCanvasRef.current) {
      if (isEraseMode) {
        fabricCanvasRef.current.defaultCursor = eraseCursor;
      } else {
        fabricCanvasRef.current.defaultCursor = "pointer";
      }
    }
  }, [isEraseMode, fabricCanvasRef]);

  useEffect(() => {
    if (!fabricCanvasRef.current) return;
    fabricCanvasRef.current.defaultCursor =
      selectedMaskingShape == null ? "pointer" : "crosshair";
  }, [fabricCanvasRef, selectedMaskingShape]);

  //handle window resize
  useEffect(() => {
    window.addEventListener("resize", calculateScale);
    return () => {
      window.removeEventListener("resize", calculateScale);
    };
  }, []);

  const setupCanvas = useCallback(() => {
    if (MediaRef.current && !MediaRef.current.srcObject) {
      MediaRef.current.srcObject = maskHandler.getStream();
      MediaRef.current.play().then(() => {
        const { videoHeight, videoWidth } = MediaRef.current ?? {
          videoHeight: 720,
          videoWidth: 1280,
        };
        if (canvasRef.current) {
          canvasRef.current.height = MediaRef.current?.videoHeight ?? 720;
          canvasRef.current.width = MediaRef.current?.videoWidth ?? 1280;
        }
        if (canvasRef.current && !fabricCanvasRef.current) {
          const fabricCanvas = new fabric.Canvas(canvasRef.current, {
            isDrawingMode: false,
            defaultCursor: "pointer",
            height: videoHeight,
            width: videoWidth,
            selection: false,
          });

          fabricCanvasRef.current = fabricCanvas;
        }
        try {
          calculateScale();
          setShapesFromMasks();
        } catch (e) {
          console.error(e);
        }
      });
    }
  }, []);

  useEffect(() => {
    setupCanvas();

    const videoElemRef = MediaRef.current;
    const fabricRef = fabricCanvasRef.current;
    return () => {
      if (videoElemRef) videoElemRef.srcObject = null;
      if (fabricRef)
        fabricRef
          .getObjects()
          .forEach((shape) => fabricCanvasRef.current?.remove(shape));
    };
  }, [setupCanvas]);

  //handle selection
  useEffect(() => {
    if (!fabricCanvasRef.current) return;
    fabricCanvasRef.current.discardActiveObject().renderAll();
    if (selectedMaskingShape === null) {
      const allObjects = fabricCanvasRef.current.getObjects();
      allObjects.forEach((object) => {
        object.selectable = true;
      });
    } else {
      const allObjects = fabricCanvasRef.current.getObjects();
      allObjects.forEach((object) => {
        object.selectable = false;
      });
    }
  }, [selectedMaskingShape]);

  //handle mask handler events
  useEffect(() => {
    const handleDeleteEvent = () => {
      fabricCanvasRef.current &&
        fabricCanvasRef.current.getActiveObjects().forEach((obj) => {
          fabricCanvasRef.current?.remove(obj);
        });

      //update mask handler
      const masks = getMasksFromShapes();
      maskHandler.setMasks(masks);
      maskHandler.addHistory(masks);

      //unselect objects
      fabricCanvasRef.current &&
        fabricCanvasRef.current.discardActiveObject().renderAll();
      maskHandler.setIsObjectSelected(false);
    };
    const handleHistoryChanged = () => {
      setShapesFromMasks();
    };
    const handleSelectedMaskingShapeChanged = () => {
      setSelectedMaskingShape(maskHandler.getSelectedMaskingShape());
    };

    const handleIsEraseModeChanged = () => {
      setIsEraseMode(maskHandler.getIsEraseMode());
    };

    const handleDeselect = () => {
      fabricCanvasRef.current &&
        fabricCanvasRef.current.discardActiveObject().renderAll();
    };

    maskHandler.on(MaskHandlerEvents.DeleteObject, handleDeleteEvent);
    maskHandler.on(MaskHandlerEvents.HistoryChanged, handleHistoryChanged);
    maskHandler.on(
      MaskHandlerEvents.SelectedMaskingShapeChanged,
      handleSelectedMaskingShapeChanged,
    );
    maskHandler.on(
      MaskHandlerEvents.IsEraseModeChanged,
      handleIsEraseModeChanged,
    );
    maskHandler.on(MaskHandlerEvents.Deselect, handleDeselect);

    return () => {
      if (maskHandler) {
        maskHandler.off(MaskHandlerEvents.DeleteObject, handleDeleteEvent);
        maskHandler.off(MaskHandlerEvents.HistoryChanged, handleHistoryChanged);
        maskHandler.off(
          MaskHandlerEvents.SelectedMaskingShapeChanged,
          handleSelectedMaskingShapeChanged,
        );
        maskHandler.off(
          MaskHandlerEvents.IsEraseModeChanged,
          handleIsEraseModeChanged,
        );
        maskHandler.off(MaskHandlerEvents.Deselect, handleDeselect);
      }
    };
  }, [maskHandler, setShapesFromMasks]);

  return (
    <Box
      zIndex={3}
      width="100%"
      maxHeight="100%"
      style={{
        aspectRatio,
      }}
      flex={1}
    >
      <Box
        width="100%"
        height="100%"
        maxWidth="100%"
        maxHeight="100%"
        position="relative"
      >
        <video
          data-testid={`VideoMaskingEditor-video`}
          data-cy={`VideoMaskingEditor-video`}
          ref={MediaRef}
          muted={true}
          style={{
            background: palette.grey[300],
            width: "100%",
            maxHeight: "100%",
          }}
          autoPlay
        />

        <FabricCanvasContainerStyled scale={videoScale}>
          <canvas
            ref={canvasRef}
            id="maskingCanvas"
            data-testid="maskingCanvas"
          ></canvas>
        </FabricCanvasContainerStyled>
      </Box>
    </Box>
  );
};

export default MaskedVideo;
