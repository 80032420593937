import React, {
  useState,
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useCallback,
} from "react";
import { useFlag } from "@proximie/feature-flag-react-sdk";
import { FeatureFlags } from "../../components/constants";

export const BRUSH_SIZES = [2, 6, 10];

interface TelestrationProviderProps {
  children: ReactNode;
}

export interface TelestrationContext {
  color: string;
  setColor: (color: string) => void;
  brushWidth: number;
  setBrushWidth: (width: number) => void;
  isDrawingMode: boolean;
  setIsDrawingMode: (mode: boolean) => void;
  isEraseLineMode: boolean;
  setIsEraseLineMode: (mode: boolean) => void;
  isCursorMode: boolean;
  toggleCursorMode: () => void;
  toggleTelestration: () => void;
  toggleEraser: () => void;
  lastFeedTelestrated: string | null;
  setLastFeedTelestrated: (feedId: string | null) => void;
  canUserEditAnnotationWidth: boolean;
  isWidthPickerVisible: boolean;
  setIsWidthPickerVisible: (mode: boolean) => void;
}

export const DEFAULT_TELESTRATION_CONTEXT: TelestrationContext = {
  color: "#FE38C7",
  setColor: () => {
    return;
  },
  brushWidth: BRUSH_SIZES[1],
  setBrushWidth: () => {
    return;
  },
  isDrawingMode: false,
  setIsDrawingMode: () => {
    return;
  },
  isEraseLineMode: false,
  setIsEraseLineMode: () => {
    return;
  },
  isCursorMode: false,
  toggleCursorMode: () => {
    return;
  },
  toggleTelestration: () => {
    return;
  },
  toggleEraser: () => {
    return;
  },
  lastFeedTelestrated: null,
  setLastFeedTelestrated: () => {
    return;
  },
  canUserEditAnnotationWidth: false,
  isWidthPickerVisible: false,
  setIsWidthPickerVisible: () => {
    return;
  },
};

export const telestrationContext = createContext<TelestrationContext>(
  DEFAULT_TELESTRATION_CONTEXT,
);

export const useTelestration = () => useContext(telestrationContext);

export const TelestrationProvider = ({
  children,
}: TelestrationProviderProps) => {
  const [color, setColor] = useState("#FE38C7");

  const [brushWidth, setBrushWidth] = useState<number>(BRUSH_SIZES[1]);

  const [lastFeedTelestrated, setLastFeedTelestrated] = useState<string | null>(
    null,
  );
  const [isCursorMode, setIsCursorMode] = useState(false);
  const [isDrawingMode, setIsDrawingMode] = useState(false);
  const [isEraseLineMode, setIsEraseLineMode] = useState(false);
  const toggleTelestration = useCallback(() => {
    setIsDrawingMode((prevState) => {
      setIsEraseLineMode(false);
      setIsCursorMode(false);
      setIsWidthPickerVisible(!prevState);
      return !prevState;
    });
  }, []);
  const toggleEraser = useCallback(() => {
    setIsDrawingMode(false);
    setIsCursorMode(false);
    setIsEraseLineMode((prevState) => !prevState);
  }, []);

  const toggleCursorMode = useCallback(() => {
    setIsDrawingMode(false);
    setIsEraseLineMode(false);
    setIsCursorMode((prev) => !prev);
  }, []);

  const canUserEditAnnotationWidth = useFlag(
    FeatureFlags.ANNOTATIONS_BETA_ENABLED,
  );

  const [isWidthPickerVisible, setIsWidthPickerVisible] =
    useState<boolean>(false);

  const value = useMemo(
    () => ({
      color,
      setColor,
      brushWidth,
      setBrushWidth,
      isDrawingMode,
      setIsDrawingMode,
      isEraseLineMode,
      setIsEraseLineMode,
      isCursorMode,
      toggleCursorMode,
      toggleTelestration,
      toggleEraser,
      lastFeedTelestrated,
      setLastFeedTelestrated,
      canUserEditAnnotationWidth,
      isWidthPickerVisible: isWidthPickerVisible && canUserEditAnnotationWidth,
      setIsWidthPickerVisible,
    }),
    [
      color,
      setColor,
      brushWidth,
      setBrushWidth,
      isDrawingMode,
      setIsDrawingMode,
      isEraseLineMode,
      setIsEraseLineMode,
      isCursorMode,
      toggleCursorMode,
      toggleTelestration,
      toggleEraser,
      lastFeedTelestrated,
      setLastFeedTelestrated,
      canUserEditAnnotationWidth,
      isWidthPickerVisible,
      setIsWidthPickerVisible,
    ],
  );

  return (
    <telestrationContext.Provider value={value}>
      {children}
    </telestrationContext.Provider>
  );
};
