import {
  CAPTURE_CARD_PATTERN,
  INTERIM_EXTERNAL_CAMERA_PATTERN,
} from "../../../constants";
import {
  ConnectionReport,
  QualityLevel,
} from "../../../monitoring/monitors/WebRTCMonitor";
import { FeedType } from "@proximie/common";

const MAX_BANDWIDTH = 7000000;
const CAPTURE_CARD_MAX_BANDWIDTH = 5000000;
const VIRTUAL_CAMERA_MAX_BANDWIDTH = 2000000;
const SCREEN_SHARE_MAX_BANDWIDTH = 2000000;

const GOOD_BANDWIDTH_PERCENT_PER_CONNECTION = 0.7;
const POOR_BANDWIDTH_PERCENT_PER_CONNECTION = 0.35;
const OUTGOING_QUALITY_SCALING: Record<QualityLevel & string, number> = {
  High: 0.95,
  Medium: 0.4,
  Low: 0.2,
};

export const GOOD_OUTGOING_BANDWIDTH_VALUE_PER_CONNECTION = ({
  params,
  qualityLevel,
}: ConnectionReport): number => {
  const OVERRIDE_GOOD_OUTGOING_BANDWIDTH_VALUE_PER_CONNECTION =
    localStorage.getItem("GOOD_OUTGOING_BANDWIDTH_VALUE_PER_CONNECTION");
  if (OVERRIDE_GOOD_OUTGOING_BANDWIDTH_VALUE_PER_CONNECTION) {
    return parseFloat(OVERRIDE_GOOD_OUTGOING_BANDWIDTH_VALUE_PER_CONNECTION);
  }

  let maxBandwidth = MAX_BANDWIDTH;
  if (INTERIM_EXTERNAL_CAMERA_PATTERN.test(params?.label ?? "")) {
    maxBandwidth = VIRTUAL_CAMERA_MAX_BANDWIDTH;
  }
  if (CAPTURE_CARD_PATTERN.test(params?.label ?? "")) {
    maxBandwidth = CAPTURE_CARD_MAX_BANDWIDTH;
  }
  if (params?.mediaType === FeedType.Screen) {
    maxBandwidth = SCREEN_SHARE_MAX_BANDWIDTH;
  }

  switch (qualityLevel ?? "High") {
    case "High":
      return (
        maxBandwidth *
        OUTGOING_QUALITY_SCALING.High *
        GOOD_BANDWIDTH_PERCENT_PER_CONNECTION
      );
    case "Medium":
      return (
        maxBandwidth *
        OUTGOING_QUALITY_SCALING.Medium *
        GOOD_BANDWIDTH_PERCENT_PER_CONNECTION
      );
    case "Low":
      return (
        maxBandwidth *
        OUTGOING_QUALITY_SCALING.Low *
        GOOD_BANDWIDTH_PERCENT_PER_CONNECTION
      );
  }
};

export const POOR_OUTGOING_BANDWIDTH_VALUE_PER_CONNECTION = ({
  params,
  qualityLevel,
}: ConnectionReport): number => {
  const OVERRIDE_POOR_OUTGOING_BANDWIDTH_VALUE_PER_CONNECTION =
    localStorage.getItem("POOR_OUTGOING_BANDWIDTH_VALUE_PER_CONNECTION");
  if (OVERRIDE_POOR_OUTGOING_BANDWIDTH_VALUE_PER_CONNECTION) {
    return parseFloat(OVERRIDE_POOR_OUTGOING_BANDWIDTH_VALUE_PER_CONNECTION);
  }

  let maxBandwidth = MAX_BANDWIDTH;
  if (INTERIM_EXTERNAL_CAMERA_PATTERN.test(params?.label ?? "")) {
    maxBandwidth = VIRTUAL_CAMERA_MAX_BANDWIDTH;
  }
  if (CAPTURE_CARD_PATTERN.test(params?.label ?? "")) {
    maxBandwidth = CAPTURE_CARD_MAX_BANDWIDTH;
  }
  if (params?.mediaType === FeedType.Screen) {
    maxBandwidth = SCREEN_SHARE_MAX_BANDWIDTH;
  }

  switch (qualityLevel ?? "High") {
    case "High":
      return (
        maxBandwidth *
        OUTGOING_QUALITY_SCALING.High *
        POOR_BANDWIDTH_PERCENT_PER_CONNECTION
      );
    case "Medium":
      return (
        maxBandwidth *
        OUTGOING_QUALITY_SCALING.Medium *
        POOR_BANDWIDTH_PERCENT_PER_CONNECTION
      );
    case "Low":
      return (
        maxBandwidth *
        OUTGOING_QUALITY_SCALING.Low *
        POOR_BANDWIDTH_PERCENT_PER_CONNECTION
      );
  }
};

export const GOOD_INCOMING_BANDWIDTH_VALUE_PER_CONNECTION = ({
  params,
  qualityLevel,
  guiStats,
}: ConnectionReport): number => {
  const OVERRIDE_GOOD_INCOMING_BANDWIDTH_VALUE_PER_CONNECTION =
    localStorage.getItem("GOOD_INCOMING_BANDWIDTH_VALUE_PER_CONNECTION");
  if (OVERRIDE_GOOD_INCOMING_BANDWIDTH_VALUE_PER_CONNECTION) {
    return parseFloat(OVERRIDE_GOOD_INCOMING_BANDWIDTH_VALUE_PER_CONNECTION);
  }

  let maxBandwidth = MAX_BANDWIDTH;
  if (INTERIM_EXTERNAL_CAMERA_PATTERN.test(params?.label ?? "")) {
    maxBandwidth = VIRTUAL_CAMERA_MAX_BANDWIDTH;
  }
  if (CAPTURE_CARD_PATTERN.test(params?.label ?? "")) {
    maxBandwidth = CAPTURE_CARD_MAX_BANDWIDTH;
  }
  if (params?.mediaType === FeedType.Screen) {
    maxBandwidth = SCREEN_SHARE_MAX_BANDWIDTH;
  }

  maxBandwidth = guiStats?.remoteBitrate ?? maxBandwidth;

  switch (qualityLevel ?? "High") {
    case "High":
      return (
        maxBandwidth *
        OUTGOING_QUALITY_SCALING.High *
        GOOD_BANDWIDTH_PERCENT_PER_CONNECTION
      );
    case "Medium":
      return (
        maxBandwidth *
        OUTGOING_QUALITY_SCALING.Medium *
        GOOD_BANDWIDTH_PERCENT_PER_CONNECTION
      );
    case "Low":
      return (
        maxBandwidth *
        OUTGOING_QUALITY_SCALING.Low *
        GOOD_BANDWIDTH_PERCENT_PER_CONNECTION
      );
  }
};

export const POOR_INCOMING_BANDWIDTH_VALUE_PER_CONNECTION = ({
  params,
  qualityLevel,
  guiStats,
}: ConnectionReport): number => {
  const OVERRIDE_POOR_INCOMING_BANDWIDTH_VALUE_PER_CONNECTION =
    localStorage.getItem("POOR_INCOMING_BANDWIDTH_VALUE_PER_CONNECTION");
  if (OVERRIDE_POOR_INCOMING_BANDWIDTH_VALUE_PER_CONNECTION) {
    return parseFloat(OVERRIDE_POOR_INCOMING_BANDWIDTH_VALUE_PER_CONNECTION);
  }

  let maxBandwidth = MAX_BANDWIDTH;
  if (INTERIM_EXTERNAL_CAMERA_PATTERN.test(params?.label ?? "")) {
    maxBandwidth = VIRTUAL_CAMERA_MAX_BANDWIDTH;
  }
  if (CAPTURE_CARD_PATTERN.test(params?.label ?? "")) {
    maxBandwidth = CAPTURE_CARD_MAX_BANDWIDTH;
  }
  if (params?.mediaType === FeedType.Screen) {
    maxBandwidth = SCREEN_SHARE_MAX_BANDWIDTH;
  }

  maxBandwidth = guiStats?.remoteBitrate ?? maxBandwidth;

  switch (qualityLevel ?? "High") {
    case "High":
      return (
        maxBandwidth *
        OUTGOING_QUALITY_SCALING.High *
        POOR_BANDWIDTH_PERCENT_PER_CONNECTION
      );
    case "Medium":
      return (
        maxBandwidth *
        OUTGOING_QUALITY_SCALING.Medium *
        POOR_BANDWIDTH_PERCENT_PER_CONNECTION
      );
    case "Low":
      return (
        maxBandwidth *
        OUTGOING_QUALITY_SCALING.Low *
        POOR_BANDWIDTH_PERCENT_PER_CONNECTION
      );
  }
};

export const GOOD_PACKET_LOSS_VALUE_PER_CONNECTION = (): number =>
  parseFloat(
    localStorage.getItem("GOOD_PACKET_LOSS_VALUE_PER_CONNECTION") ?? "0.1",
  );
export const POOR_PACKET_LOSS_VALUE_PER_CONNECTION = (): number =>
  parseFloat(
    localStorage.getItem("POOR_PACKET_LOSS_VALUE_PER_CONNECTION") ?? "1",
  );

export const GOOD_LATENCY_VALUE_PER_CONNECTION = (): number =>
  parseFloat(
    localStorage.getItem("GOOD_LATENCY_VALUE_PER_CONNECTION") ?? "250",
  );

export const POOR_LATENCY_VALUE_PER_CONNECTION = (): number =>
  parseFloat(
    localStorage.getItem("POOR_LATENCY_VALUE_PER_CONNECTION") ?? "400",
  );
