import {
  Button,
  IconButton,
  ParticipantList,
  ParticipantProps,
} from "@proximie/components";
import React, { FC } from "react";
import { LiveSessionParticipantsStyled } from "./LiveSessionParticipants.style";
import { generateURLRoot } from "../../utils/index";
import { useSessionContext } from "../../contexts/session-context/session-context";
import { Permission } from "../../utils/checkPermission";
import { useEnvironment } from "../../hooks/useEnvironment";

export interface LiveSessionParticipantsProps {
  sessionOwner?: ParticipantProps;
  participants: ParticipantProps[];
  muteAllOnClick: () => void;
  appointmentId?: number;
}

const LiveSessionParticipants: FC<LiveSessionParticipantsProps> = (
  props: LiveSessionParticipantsProps,
) => {
  const { ShowForPermission } = useSessionContext();
  const { sessionOwner, participants, muteAllOnClick } = props;
  const { domain } = useEnvironment();

  const linkToEditSession = () => {
    const urlRoot = generateURLRoot(domain);
    window.location.href = `${urlRoot}/session/${props.appointmentId}`;
  };

  return (
    <LiveSessionParticipantsStyled>
      {sessionOwner && (
        <ParticipantList title="Session Owner" participants={[sessionOwner]} />
      )}
      <ParticipantList title="Participants" participants={participants}>
        <Button
          variant="secondary"
          data-cy="mute-all-button"
          onClick={muteAllOnClick}
        >
          Mute All
        </Button>
        <ShowForPermission permission={Permission.INVITE_PARTICIPANTS}>
          <IconButton
            aria-label="Add Participant"
            name="addParticipant"
            color="primary"
            dataCy="add-participant-button"
            onClick={linkToEditSession}
            size="large"
          />
        </ShowForPermission>
      </ParticipantList>
    </LiveSessionParticipantsStyled>
  );
};

export default LiveSessionParticipants;
