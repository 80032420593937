import Monitor, { MonitorOptions } from "./Monitor";
import EndpointDcpSession from "../Endpoints/EndpointDcpSession";
import { IDevicePresencePayload, emitterOnOff } from "@proximie/dcp";
import { IComponentPublishInfo, SessionRemoteDevice } from "@proximie/dcp-mqtt";
import DeviceDcp from "../Devices/DeviceDcp";

export interface MonitorDeviceDcpOptions extends MonitorOptions {
  hostDeviceId: string;
}

export default class MonitorDeviceDcp extends Monitor {
  private devices: DeviceDcp[] = [];
  private dcpListeners: Record<string, { [event: string]: () => void }> = {};

  constructor(
    protected room: EndpointDcpSession,
    protected options: MonitorDeviceDcpOptions,
  ) {
    super(room);

    this.room.manager &&
      this.room.manager.on("device:presence", this.devicePresenceHandler);
  }

  override open(): Promise<void> {
    return Promise.resolve();
  }

  private removeComponent = (
    deviceId: string,
    componentName?: string,
  ): void => {
    console.debug(
      { deviceId },
      `MonitorDeviceDcp:removeComponent - component=${componentName}`,
    );
    this.devices = this.devices.reduce(
      (devices: DeviceDcp[], device: DeviceDcp): DeviceDcp[] => {
        if (
          device.deviceId === deviceId &&
          (!componentName || device.options.component == componentName)
        ) {
          console.debug(
            { deviceId },
            `Closing device - component=${device.options.component}`,
          );
          process.nextTick(() => {
            // only notify once we've updated the devices list
            this.emit("removed", device);
          });
          // notify the dependent connections
          device.emit("closed");
        } else {
          devices.push(device);
        }
        return devices;
      },
      [],
    );
  };

  private removeDevice = (deviceId: string): void => {
    console.debug({ deviceId }, "MonitorDeviceDcp:removeDevice");
    this.removeComponent(deviceId);

    // get rid of any listeners on this DCP device
    if (this.dcpListeners[deviceId]) {
      Object.values(this.dcpListeners[deviceId]).forEach(
        (off: () => void): void => {
          off();
        },
      );
      delete this.dcpListeners[deviceId];
    }
  };

  private addComponent(
    deviceId: string,
    dcpDevice: SessionRemoteDevice,
    component: string,
  ): void {
    const existing = this.devices.findIndex(
      (device: DeviceDcp): boolean =>
        device.deviceId === deviceId && device.options.component === component,
    );
    if (existing !== -1) {
      console.debug({ deviceId }, "Device already exists - ignoring");
      return;
    }

    console.debug(
      { deviceId },
      `Creating new device for component ${component}`,
    );

    const newDevice = new DeviceDcp(deviceId, {
      dcpDevice,
      component,
      hostDeviceId: this.options.hostDeviceId,
    });
    this.devices.push(newDevice);
    this.emit("added", newDevice);
  }

  private devicePresenceHandler = (
    deviceId: string,
    presence: IDevicePresencePayload,
  ): void => {
    console.debug(
      { deviceId },
      "Monitor:devicePresenceHandler - Got device presence=",
      presence,
    );

    if (!this.room.manager) {
      console.warn("no broker operational");
      return;
    }

    if (presence.available) {
      const dcpDevice = this.room.manager.getDevice(deviceId);

      if (!dcpDevice) {
        console.warn({ deviceId }, "Device not defined");
        return;
      }

      // get any existing components
      const componentInfos = dcpDevice.getAllComponents();
      componentInfos.forEach((componentInfo: IComponentPublishInfo): void => {
        if (componentInfo?.servicesPayload) {
          this.addComponent(deviceId, dcpDevice, componentInfo.name);
        }
      });

      this.dcpListeners[deviceId] = {
        "component:services": emitterOnOff(
          dcpDevice,
          "component:services",
          (componentName: string): void => {
            if (dcpDevice.getComponentServices(componentName)) {
              this.addComponent(deviceId, dcpDevice, componentName);
            }
          },
        ),
        "component:off": emitterOnOff(
          dcpDevice,
          "component:off",
          (componentName: string): void => {
            this.removeComponent(deviceId, componentName);
          },
        ),
      };
    } else {
      // delete all components on this device
      this.removeDevice(deviceId);
    }
  };

  override get deviceList(): DeviceDcp[] {
    return this.devices;
  }

  override close(): Promise<void> {
    Object.keys(this.dcpListeners).forEach(this.removeDevice);

    this.room.manager &&
      this.room.manager.off("device:presence", this.devicePresenceHandler);
    return Promise.resolve();
  }
}
