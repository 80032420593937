import { LeafMonitor } from "../Monitor";
import {
  SummaryStats,
  GuiStats,
} from "../../../lib/ServerAdapter/Quality/Quality";
import FixedFifo from "../../../lib/ServerAdapter/Quality/FixedFifo";
import { ConnectionMetadata } from "@proximie/common";

export type QualityLevel = "High" | "Medium" | "Low";
export type AdaptiveStreamingMode = "Manual" | "Automatic";
export const QualityLevels: QualityLevel[] = ["Low", "Medium", "High"];
export const AdaptiveStreamingModes: AdaptiveStreamingMode[] = [
  "Manual",
  "Automatic",
];

export type Direction = "None" | "Incoming" | "Outgoing" | "Both";
export const Directions: Direction[] = ["None", "Incoming", "Outgoing", "Both"];

export type ConnectionReport = {
  statsHistory: FixedFifo<SummaryStats> | undefined;
  guiStats: GuiStats | null | undefined;
  qualityLevel?: QualityLevel;
  adaptiveStreamingMode?: AdaptiveStreamingMode;
  direction?: Direction;
  params?: ConnectionMetadata;
};

type ConnectionsReport = Record<string, ConnectionReport>;

export type WebRTCReport = {
  connections: ConnectionsReport;
};

type StreamID = string;
type StatsHistoryMap = Record<StreamID, FixedFifo<SummaryStats> | undefined>;
type GuiStatsMap = Record<StreamID, GuiStats | null | undefined>;

export class WebRTCMonitor extends LeafMonitor<WebRTCReport> {
  private statsHistoryMap: StatsHistoryMap = {};
  private guiStatsMap: GuiStatsMap = {};

  constructor() {
    super({});
  }

  run() {
    const videos = this.context?.serverAdapterContext?.videos ?? [];

    this.statsHistoryMap = Object.entries(this.statsHistoryMap)
      .filter(([key, _]) => videos.some((v) => v.streamId === key))
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {} as StatsHistoryMap);

    this.guiStatsMap = Object.entries(this.guiStatsMap)
      .filter(([key, _]) => videos.some((v) => v.streamId === key))
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {} as GuiStatsMap);

    for (const video of videos) {
      if (!this.statsHistoryMap[video.streamId]) {
        this.statsHistoryMap[video.streamId] = new FixedFifo<SummaryStats>(10);
      }

      video.quality?.statsHistory.forEach((videoSummary) => {
        this.statsHistoryMap[video.streamId]?.pushIfNotExists(
          videoSummary,
          (v) => v.timestamp === videoSummary.timestamp,
        );
      });

      if (video.quality?.stats) {
        const newGuiStat = video.quality.stats;
        if (this.guiStatsMap[video.streamId] != null) {
          const previousRtt = this.guiStatsMap[video.streamId]?.rtt;
          if (newGuiStat.rtt === 0 && previousRtt != null) {
            newGuiStat.rtt = previousRtt;
          }
        }
        this.guiStatsMap[video.streamId] = newGuiStat;
      }
    }

    const connections: ConnectionsReport =
      videos.reduce((acc: ConnectionsReport, video) => {
        const controls = video.quality?.controls;
        const statsHistory =
          this.statsHistoryMap[video.streamId] ?? video.quality?.statsHistory;
        const guiStats =
          this.guiStatsMap[video.streamId] ?? video.quality?.stats;

        const bitrateControl = controls?.["bitrate"];
        const resolutionControl = controls?.["resolution"];

        let qualityLevel = QualityLevels[resolutionControl?.value ?? 2];
        if (bitrateControl) {
          let value: number | undefined = bitrateControl?.value;

          switch (value) {
            case undefined:
              value = 2;
              break;
            case 2:
              value = 1;
              break;
            case 3:
              value = 2;
              break;
          }
          qualityLevel = QualityLevels[value];
        }

        acc[video.streamId] = {
          statsHistory,
          guiStats,
          qualityLevel,
          adaptiveStreamingMode:
            AdaptiveStreamingModes[controls?.["mode"]?.value ?? 1],
          direction: Directions[video.direction ?? 0],
          params: video.params,
        };
        return acc;
      }, {}) ?? {};

    const audio = this.context?.serverAdapterContext?.audio;
    if (audio) {
      connections[audio.streamId] = {
        statsHistory: audio.quality?.statsHistory,
        guiStats: audio.quality?.stats,
        params: audio.params,
        direction: Directions[audio.direction ?? 0],
      };
    }

    return { connections };
  }
}
