import * as t from "io-ts";
import { DateFromISOString } from "io-ts-types/lib/DateFromISOString";

// Reserved socket.io events
// these are deprecated - only the SocketIOClientWrapper should care about such things...
export const SocketConnect = "connect";
export const SocketError = "connect_error";
export const SocketDisconnect = "disconnect";
export const SocketServerDisconnect = "io server disconnect";

// Requests

export const CredentialsRefreshed = "credentials_refreshed";
export const RequestMediaSessionSyncTopic = "media_session_sync";
export const RequestSendMediaSessionEvent = "media_session_event";

// Request body types

export const MediaSessionEventType = {
  sessionLaunched: "session_launched",
  getPermissions: "get_permissions",
  sessionStarted: "session_started",
  joinSession: "join_session",
  leaveSession: "participant_left",
  participant_added: "participant_added",
  mute: "mute",
  endSession: "end_session",
  telestration: "telestration",
  chat: "chat",
  retransmit: "retransmit",
  getRecordingStatus: "get_recording_status",
  recordingStatus: "recording_status",
  broadcast: "broadcast",
  spotlightStream: "spotlight_stream",
  getSpotlightHistory: "get_spotlight_history",
  sessionJoined: "session_joined",
  getFeedParams: "get_feed_params",
} as const;

export type ObjectAsEnum<T> = T[keyof T];

export type MediaSessionEventType = ObjectAsEnum<typeof MediaSessionEventType>;

export const MediaSessionEventBroadcastTopics = {
  ptzControl: "ptz_control",
  privacyMask: "privacy_mask",
  videoKicked: "video_kicked",
};

export const MandatoryUserCapabilities = t.type({
  userAgent: t.string,
});
export type MandatoryUserCapabilities = t.TypeOf<
  typeof MandatoryUserCapabilities
>;

export const OptionalUserCapabilities = t.partial({
  language: t.string,
  isTouchSupport: t.boolean,
  screenSize: t.type({
    width: t.number,
    height: t.number,
  }),
  isCookieEnabled: t.boolean,
  logicCores: t.number,
  connectionDownlinkMbps: t.number,
  connectionType: t.string,
  memoryGb: t.number,
});
export type OptionalUserCapabilities = t.TypeOf<
  typeof OptionalUserCapabilities
>;

export const UserCapabilities = t.intersection([
  MandatoryUserCapabilities,
  OptionalUserCapabilities,
  t.record(t.string, t.unknown),
]);
export type UserCapabilities = t.TypeOf<typeof UserCapabilities>;

export const DetailMediaSessionSyncRetransmitEvents = t.partial({
  lastInOrderSequenceNumber: t.union([t.undefined, t.number]),
  firstOutOfOrderSequenceNumber: t.union([t.undefined, t.number]),
});

export const DetailMediaSessionSyncGetSequenceInfo = t.type({
  sequenceNumber: t.number,
});

export const ResponseBodyMediaSessionSync = t.type({
  status: t.string,
  detail: t.union([t.undefined, DetailMediaSessionSyncGetSequenceInfo]),
  error: t.union([t.undefined, t.string]),
});

export enum MediaSessionEventResponseStatus {
  Success = "success",
  Error = "error",
  Accepted = "accepted",
}

// This replicates the RTCIceServer TypeScript type
const IceServer = t.type({
  username: t.union([t.string, t.undefined]),
  credential: t.union([t.string, t.undefined]),
  urls: t.string,
});

const JanusServerURL = t.type({
  url: t.string,
  httpUrl: t.string,
  apiKey: t.string,
});
export type JanusServerURLPresentation = t.TypeOf<typeof JanusServerURL>;

const MandatoryMediaConnectionDetails = t.type({
  audioServer: JanusServerURL,
  videoServer: JanusServerURL,
  username: t.string,
  credential: t.string,
});

const DcpBrokerConfig = t.type({
  wssUrl: t.string,
  mqttsUrl: t.string,
});
export type DcpBrokerConfig = t.TypeOf<typeof DcpBrokerConfig>;

export const WatchRTCConfig = t.type({
  rtcApiKey: t.string,
  proxyUrl: t.string,
});

const OptionalMediaConnectionDetails = t.partial({
  userId: t.string,
  iceServers: t.array(IceServer),
  // Needs decoding on the media-client side
  startedAt: DateFromISOString,
  dcpHomeBroker: DcpBrokerConfig,
  dcpSessionBroker: DcpBrokerConfig,
  newRelicLogUrl: t.string,
  watchRTC: WatchRTCConfig,
});

export const MediaConnectionDetails = t.intersection([
  MandatoryMediaConnectionDetails,
  OptionalMediaConnectionDetails,
]);

export const GetFeedParamsReponse = t.type({
  feedId: t.string,
  order: t.string,
});
export type GetFeedParamsReponse = t.TypeOf<typeof GetFeedParamsReponse>;

// Exports

export type MediaConnectionDetails = t.TypeOf<typeof MediaConnectionDetails>;

export type DetailMediaSessionSyncGetSequenceInfo = t.TypeOf<
  typeof DetailMediaSessionSyncGetSequenceInfo
>;

export type DetailMediaSessionSyncRetransmitEvents = t.TypeOf<
  typeof DetailMediaSessionSyncRetransmitEvents
>;

export type ResponseBodyMediaSessionSync = t.TypeOf<
  typeof ResponseBodyMediaSessionSync
>;

export const MediaSessionEventResponseDetailsJoin = t.type({
  serverParams: MediaConnectionDetails,
});

export type MediaSessionEventResponseDetailsJoinPresentation = t.TypeOf<
  typeof MediaSessionEventResponseDetailsJoin
>;

export const RecordingStatusEvent = t.type({
  isEnabled: t.boolean,
  timestamp: t.number,
  sequenceNumber: t.number,
});

export const SpotlightEvent = t.type({
  streamId: t.union([t.string, t.null]),
  profileUuid: t.string,
  timestamp: t.number,
  sequenceNumber: t.number,
});

export const RecordingStatusEvents = t.array(RecordingStatusEvent);

export type RecordingStatusEvent = t.TypeOf<typeof RecordingStatusEvent>;

export type RecordingStatusEvents = t.TypeOf<typeof RecordingStatusEvents>;

export const SpotlightEvents = t.array(SpotlightEvent);

export type SpotlightEvent = t.TypeOf<typeof SpotlightEvent>;

export type SpotlightEvents = t.TypeOf<typeof SpotlightEvents>;

export type MediaSessionEventResponseDetails =
  | MediaSessionEventResponseDetailsJoinPresentation
  | RecordingStatusEvents
  | SpotlightEvents
  | GetFeedParamsReponse
  // since the io-ts parsing translates all string dates into Date objects
  // we would need an additional type here that replicates the data structures
  // with the dates defined as a string.  Since, I'm not keen on maintaining
  // duplicate (almost identical) types we'll allow an unknown type here
  | unknown;

export type MediaSessionEventDetailsTelestration = {
  streamId: string;
  command: string;
  [key: string]: unknown;
};

export const MediaSessionEventDetailsSpotlight = t.type({
  streamId: t.union([t.string, t.null]),
});

export const MediaSessionEventDetailsPersistanceSpotlight = t.type({
  streamId: t.union([t.string, t.null]),
  profileUuid: t.string,
});

export type MediaSessionEventDetailsSpotlight = t.TypeOf<
  typeof MediaSessionEventDetailsSpotlight
>;

export type MediaSessionEventDetailsPersistanceSpotlight = t.TypeOf<
  typeof MediaSessionEventDetailsPersistanceSpotlight
>;
export type MediaSessionEventDetailsChat = {
  author?: string;
  message: string;
};

//TODO - is this still valid?
export const MediaSessionEventDetailsParticipantAdded = t.type({
  participantId: t.string,
});
export type MediaSessionEventDetailsParticipantAdded = t.TypeOf<
  typeof MediaSessionEventDetailsParticipantAdded
>;

export const MediaSessionEventDetailsMute = t.type({
  state: t.boolean,
  streamId: t.union([t.string, t.literal("all")]),
});
export type MediaSessionEventDetailsMute = t.TypeOf<
  typeof MediaSessionEventDetailsMute
>;

export type MediaSessionEventDetailsRetransmit = {
  lastInOrderSequenceNumber?: number;
  firstOutOfOrderSequenceNumber?: number;
};

export type MediaSessionEventDetailsSessionStarted = {
  mediaSessionStartedAt: string;
};

export type MediaSessionEventDetailsLeaveSession = {
  reason: string;
};

export const MediaSessionEventDetailsRecordingStatus = t.type({
  isEnabled: t.boolean,
});

export type MediaSessionEventDetailsRecordingStatus = t.TypeOf<
  typeof MediaSessionEventDetailsRecordingStatus
>;

export enum PtzCommands {
  StatusRequest = "status_request",
  StatusNotification = "status_notification",
  ControlRequest = "control_request",
  ControlCancel = "control_cancel",
  ControlRescind = "control_rescind",
  ControlDeny = "control_deny",
}

export enum PtzMountings {
  Inverted = "inverted",
  Standard = "standard",
}

export const MediaSessionEventDetailsPtzControl = t.union([
  t.type({
    command: t.literal(PtzCommands.StatusRequest),
    deviceId: t.string,
  }),
  t.type({
    command: t.literal(PtzCommands.StatusNotification),
    controllerId: t.number,
    requesterId: t.number,
    deviceId: t.string,
  }),
  t.type({
    command: t.literal(PtzCommands.ControlRequest),
    deviceId: t.string,
  }),
  t.type({
    command: t.literal(PtzCommands.ControlCancel),
    deviceId: t.string,
  }),
  t.type({
    command: t.literal(PtzCommands.ControlRescind),
    deviceId: t.string,
  }),
  t.type({
    command: t.literal(PtzCommands.ControlDeny),
    deviceId: t.string,
  }),
]);

export type MediaSessionEventDetailsPtzControl = t.TypeOf<
  typeof MediaSessionEventDetailsPtzControl
>;

export const MediaSessionEventDetailsBroadcast = t.type({
  topic: t.string,
  data: t.UnknownRecord,
});

export type MediaSessionEventDetailsBroadcast = t.TypeOf<
  typeof MediaSessionEventDetailsBroadcast
>;

export type MediaSessionEventDetails =
  | MediaSessionEventDetailsTelestration
  | MediaSessionEventDetailsSpotlight
  | MediaSessionEventDetailsChat
  | MediaSessionEventDetailsParticipantAdded
  | MediaSessionEventDetailsMute
  | MediaSessionEventDetailsRetransmit
  | MediaSessionEventDetailsSessionStarted
  | MediaSessionEventDetailsLeaveSession
  | MediaSessionEventDetailsRecordingStatus
  | MediaSessionEventDetailsPtzControl
  | MediaSessionEventDetailsBroadcast
  // since the io-ts parsing translates all string dates into Date objects
  // we would need an additional type here that replicates the data structures
  // with the dates defined as a string.  Since, I'm not keen on maintaining
  // duplicate (almost identical) types we'll allow an unknown type here
  | unknown;

export type MediaSessionEventNotification = [
  number, // sequenceNumber
  number, // profileId
  MediaSessionEventDetails, // details
  number, // timestamp
  string, // userId
];

export type MediaSessionEventStore = [
  number, // sequenceNumber
  string, // topic
  number, // profileId
  MediaSessionEventDetails, // details
  number, // timestamp
  string, // userId
];

//TODO - maybe this should be an array too?
export type MediaSessionEventResponse = {
  status: MediaSessionEventResponseStatus;
  sequenceNumber?: number;
  detail?: MediaSessionEventResponseDetails;
};

export type MediaSessionEventObject = {
  sequenceNumber?: number;
  topic?: MediaSessionEventType;
  profileId: number;
  detail: MediaSessionEventDetails;
  timestamp?: number;
  userId: string;
};

export function makeMediaSessionEventObject(
  profileId: number,
  detail: MediaSessionEventDetails,
  userId: string,
  timestamp: number = Date.now(),
): MediaSessionEventObject {
  return {
    profileId,
    detail,
    userId,
    timestamp,
  };
}

export function makeMediaSessionEventNotification(
  profileId: number,
  detail: MediaSessionEventDetails,
  userId: string,
  sequenceNumber = 0,
  timestamp = Date.now(),
): MediaSessionEventNotification {
  return [sequenceNumber, profileId, detail, timestamp, userId];
}

export function convertMediaSessionEventObjectToMediaSessionEventStore(
  topic: MediaSessionEventType,
  event: MediaSessionEventObject,
): MediaSessionEventStore {
  return [
    0,
    topic,
    event.profileId,
    event.detail,
    event.timestamp || Date.now(),
    event.userId,
  ];
}

export function extractTopicFromMediaSessionEventStore(
  store: MediaSessionEventStore,
): string {
  return store[1];
}

export function convertMediaSessionEventStoreToMediaSessionEventNotification(
  store: MediaSessionEventStore,
): MediaSessionEventNotification {
  return store.filter(
    (_, i) => i !== 1,
  ) as unknown as MediaSessionEventNotification;
}

export function convertMediaSessionEventNotificationToMediaSessionEventObject(
  notification: MediaSessionEventNotification,
): MediaSessionEventObject {
  return {
    sequenceNumber: notification[0],
    profileId: notification[1],
    detail: notification[2],
    timestamp: notification[3],
    userId: notification[4],
  };
}
