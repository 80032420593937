import ConnectionJanusVideoIncoming from "../Janus/ConnectionJanusVideoIncoming";
import { Direction, ConnectionType } from "../Connection";
import { DeviceType } from "@proximie/common";
import { EndpointType } from "../../Endpoints/Endpoint";

export default class ConnectionDcpVideoIncoming extends ConnectionJanusVideoIncoming {
  override connectionType = ConnectionType.DcpVideoIncoming;
  override direction = Direction.Incoming;

  override async kick(): Promise<void> {
    console.log("Closing DCP incoming");
    const endpoint = this.endpoints[EndpointType.Dcp];
    if (!endpoint) {
      throw new Error("No DCP endpoint defined");
    }

    const device = this.devices[DeviceType.Dcp];
    if (!device) {
      throw new Error("DCP device does not exist");
    }

    await endpoint.request(
      {
        deviceId: (device as any).deviceId,
        component: (device as any).options.component,
        serviceName: (device as any).serviceName,
      },
      {
        mode: "none",
        //LATER - pre-LMS backwards compatibility
        streaming: false,
      },
    );

    // kick the connection from Janus so we see an immediate effect
    return super.kick();
  }
}
