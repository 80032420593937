import React, { ReactElement } from "react";
import styled from "@emotion/styled";
import { Error, Info, Warning } from "@mui/icons-material";
import { Colors } from "../../index";

export type AlertType = "grey" | "error" | "warning" | "info" | "success";

type AlertContainerProps = {
  bgColor: string;
  borderColor: string;
  textColor: string;
};

type AlertMapper = (colors: Colors) => Record<
  AlertType,
  {
    icon: ReactElement;
    iconColor: string;
    textColor: string;
    bgColor: string;
    borderColor: string;
  }
>;

export const InlineAlertTypeMapping: AlertMapper = (colors: Colors) => ({
  grey: {
    icon: <Error />,
    iconColor: colors.BlackA500,
    textColor: colors.BlackA900,
    bgColor: colors.BlackA500,
    borderColor: colors.Grey400,
  },
  error: {
    icon: <Error />,
    iconColor: colors.Red500,
    textColor: colors.Red900,
    bgColor: colors.Red50,
    borderColor: colors.Red200,
  },
  warning: {
    icon: <Warning />,
    iconColor: colors.Amber500,
    textColor: colors.BlackA900,
    bgColor: colors.Amber50,
    borderColor: colors.Amber200,
  },
  info: {
    icon: <Info />,
    iconColor: colors.Blue600,
    textColor: colors.Blue800,
    bgColor: colors.Blue50,
    borderColor: colors.Blue300,
  },
  success: {
    icon: <Error />,
    iconColor: colors.Green500,
    textColor: colors.Green900,
    bgColor: colors.Green50,
    borderColor: colors.Green300,
  },
});

export const RegularAlertTypeMapping: AlertMapper = (colors: Colors) => ({
  grey: {
    icon: <Error />,
    iconColor: colors.BlackA900,
    textColor: colors.BlackA900,
    bgColor: colors.White,
    borderColor: colors.White,
  },
  error: {
    icon: <Error />,
    iconColor: colors.White,
    textColor: colors.White,
    bgColor: colors.Red700,
    borderColor: colors.Red700,
  },
  warning: {
    icon: <Warning />,
    iconColor: colors.Black,
    textColor: colors.Black,
    bgColor: colors.Amber400,
    borderColor: colors.Amber400,
  },
  info: {
    icon: <Info />,
    iconColor: colors.White,
    textColor: colors.White,
    bgColor: colors.Blue700,
    borderColor: colors.Blue700,
  },
  success: {
    icon: <Error />,
    iconColor: colors.White,
    textColor: colors.White,
    bgColor: colors.Green600,
    borderColor: colors.Green600,
  },
});

export const InlineAlertContainer = styled.div`
  ${(props: AlertContainerProps) => `
    width: fit-content;
    minHeight: 36px;
    margin: 1em auto 0;
    display: flex;
    flexDirection: row;
    flexGrow: 0;
    alignItems: flex-start;
    gap: 8px;
    borderRadius: 4px;
    boxSizing: border-box;
    padding: 8px;
    border: 1px solid ${props.borderColor};
    backgroundColor: ${props.bgColor};
    color: ${props.textColor};
  `}
`;

export const RegularAlertContainer = styled.div`
  ${(props: AlertContainerProps) => `
    display: flex;
    boxSizing: content-box;
    padding: 14px 16px;
    border: 1px solid ${props.borderColor};
    backgroundColor: ${props.bgColor};
    color: ${props.textColor};
  `}
`;

export const AlertIcon = styled.div((props: { color: string }) => ({
  color: props.color,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export const AlertContent = styled.div`
  margin-top: 1px;
  display: flex;
  align-self: stretch;

  & header {
  }
`;
