import useSWR, { SWRConfiguration } from "swr";
import axios from "axios";
import { augmentObjectWithSequentialID } from "../common/augmentObjectWithLegacyID";
import { Member } from "@proximie/api";
import { useEnvironment } from "../useEnvironment";

export const useMembers = (
  organisationId: string | undefined,
  opts?: SWRConfiguration,
) => {
  const { apiUrl, api } = useEnvironment();

  const getMembers: (organisationId: string) => () => Promise<Member[]> =
    (organisationId?: string) => async () => {
      const members = (
        await axios.get(
          `${apiUrl}/api/v2/organisations/${organisationId}/members`,
        )
      ).data.members;

      return await augmentObjectWithSequentialID<Member>(members, api.baseUrl);
    };

  const { data, error } = useSWR<Member[]>(
    organisationId ? `organisation-${organisationId}-members` : null,
    getMembers(organisationId || ""),
    opts,
  );

  return {
    members: data,
    areMembersLoading: !error && !data,
    membersError: error,
  };
};
