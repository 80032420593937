import { DeviceType, FeedType } from "@proximie/common";
import DeviceLocal from "./DeviceLocal";
import Connection from "../Connections/Connection";

export default class DeviceLocalMicrophone extends DeviceLocal {
  public override readonly deviceType = DeviceType.Microphone;
  public override readonly mediaType = FeedType.Audio;

  public override invoke(): Connection | null {
    throw new Error("Cannot make a connection from a microphone device");
  }
}
