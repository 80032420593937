import { liveApiSockets } from "@proximie/dataregion-api";
import {
  IComponentRequestPayload,
  IComponentServicesPayload,
  IComponentStatePayload,
  IDevicePresencePayload,
  INamedCapabilityStateValues,
  INamedCapabilityDefinitions,
  ICapabilityDefinitionRange,
} from "@proximie/dcp";
import { LocalDevice } from "@proximie/dcp-mqtt";
import SocketIoClientWrapper from "../wrappers/SocketIOClientWrapper/SocketIOClientWrapper";
import OutgoingVideo from "../../lib/models/OutgoingVideo";

const CONTROL_TIMEOUT = 20000;
const SERVICE_NAME = "camera-service";

/*
Remembering that the websocket/Redis architecture implements
a logical bus.  Some example message flows are shown here:


Join with no existing controller:

PTZ owner           controller(1)    requester(2)
<----------------------------------status_request-->
<--status_notif(0)--------------------------------->
<---------------------------------control_request-->
<--status_notif(2)--------------------------------->
<-------------------------------------control_ptz-->


Current controller rescinds:

PTZ owner          controller(1)    requester(2)
<---------------------------------status_request-->
<--status_notif(1)-------------------------------->
<--------------------------------control_request-->
Tstart
<------------------control_rescind---------------->
Tend
<--status_notif(2)-------------------------------->
<------------------------------------control_ptz-->


Current controller denies:

PTZ owner          controller(1)    requester(2)
<---------------------------------status_request-->
<--status_notif(1)-------------------------------->
<--------------------------------control_request-->
Tstart
<------------------control_deny------------------->
Tend
<--status_notif(1)-------------------------------->
<------------------control_ptz-------------------->


Current controller timeout:

PTZ owner          controller(1)    requester(2)
<---------------------------------status_request-->
<--status_notif(1)-------------------------------->
<--------------------------------control_request-->
Tstart
...
Tend
<--status_notif(2)-------------------------------->
<------------------------------------control_ptz-->


Controller leaves session (no requester waiting):

PTZ owner          controller(1)    requester(2)
<------------------leave_session------------------>
<--status_notif()--------------------------------->


Controller leaves session with request outstanding:

PTZ owner          controller(1)    requester(2)
<---------------------------------status_request-->
<--status_notif(1)-------------------------------->
<--------------------------------control_request-->
Tstart
<------------------leave_session------------------>
Tend
<--status_notif(2)-------------------------------->
*/

export const WebRTCToDcpMapping: Record<string, string> = {
  pan: "PAN",
  tilt: "TILT",
  zoom: "ZOOM",
};

const DcpToWebRTCMapping: Record<string, string> = {
  PAN: "pan",
  TILT: "tilt",
  ZOOM: "zoom",
};

export class PtzOwner extends LocalDevice {
  private controllerId = 0;
  private requesterId = 0;
  private timerId: ReturnType<typeof setTimeout> | null = null;
  private settings: INamedCapabilityStateValues = {};
  private connections = new Map<OutgoingVideo, boolean>();

  constructor(
    private deviceId: string,
    connection: OutgoingVideo,
    private socket: SocketIoClientWrapper | null,
    private controls?: Record<string, boolean>,
  ) {
    super();

    this.addConnection(connection);

    this.socket &&
      this.socket.onBroadcast(
        liveApiSockets.MediaSessionEventBroadcastTopics.ptzControl,
        //eslint-disable-next-line sonarjs/cognitive-complexity
        (
          control: liveApiSockets.MediaSessionEventDetailsPtzControl,
          profileId: number,
        ) => {
          if (control.deviceId !== this.deviceId) {
            console.log("Wrong deviceId");
            return;
          }

          switch (control.command) {
            case liveApiSockets.PtzCommands.StatusRequest:
              this.sendControlNotification();
              break;
            case liveApiSockets.PtzCommands.StatusNotification:
              break;
            case liveApiSockets.PtzCommands.ControlRequest:
              if (this.controllerId === profileId) {
                // user already has control - re-send the status notification
                this.sendControlNotification();
              } else if (this.controllerId === 0) {
                // no-one is controlling - assign immediately
                this.controllerId = profileId;
                this.sendControlNotification();
              } else if (this.requesterId !== 0) {
                // we already have someone waiting - re-send the status notification
                this.sendControlNotification();
              } else {
                this.requesterId = profileId;
                if (this.timerId) {
                  clearTimeout(this.timerId);
                }
                this.timerId = setTimeout(() => {
                  this.controllerId = this.requesterId;
                  this.requesterId = 0;
                  this.sendControlNotification();
                }, CONTROL_TIMEOUT);
                this.sendControlNotification();
              }
              break;
            case liveApiSockets.PtzCommands.ControlCancel:
            case liveApiSockets.PtzCommands.ControlRescind:
              this.changeController(profileId);
              break;
            case liveApiSockets.PtzCommands.ControlDeny:
              if (profileId === this.controllerId) {
                this.requesterId = 0;
                if (this.timerId) {
                  clearTimeout(this.timerId);
                }
                this.sendControlNotification();
              }
              break;
          }
        },
      );

    this.socket &&
      this.socket.on(
        liveApiSockets.MediaSessionEventType.leaveSession,
        (_: liveApiSockets.MediaSessionEventDetails, profileId: number) => {
          // if there are no more users with the same profileId (eg. on different
          // devices) then relinquish control
          //TODO - we need to get the list of participants and see if this profileId
          // still exists.  But, this won't actually work since both devices will share
          // a audio participant instance!!!
          this.changeController(profileId);
        },
      );
  }

  private async sendControlNotification(): Promise<void> {
    try {
      await this.socket?.broadcastAsync(
        liveApiSockets.MediaSessionEventBroadcastTopics.ptzControl,
        {
          command: liveApiSockets.PtzCommands.StatusNotification,
          deviceId: this.deviceId,
          controllerId: this.controllerId,
          requesterId: this.requesterId,
        } as liveApiSockets.MediaSessionEventDetailsPtzControl,
      );
    } catch (error) {
      console.warn("Error sending PTZ status notification=", error);
    }
  }

  private changeController(profileId: number): void {
    if (profileId === this.controllerId) {
      if (this.timerId) {
        clearTimeout(this.timerId);
        this.timerId = null;
      }
      this.controllerId = this.requesterId;
      this.requesterId = 0;
      this.sendControlNotification();
    }
    if (profileId === this.requesterId) {
      if (this.timerId) {
        clearTimeout(this.timerId);
        this.timerId = null;
      }
      this.requesterId = 0;
      this.sendControlNotification();
    }
  }

  private async applyRequest(command: string, value: number): Promise<void> {
    const webrtcCommand = DcpToWebRTCMapping[command];
    if (!webrtcCommand) {
      throw new Error(`Invalid command=${command}`);
    }

    const connection = this.getConnection();

    console.log(
      { streamId: connection.streamId },
      `PTZ APPLY ${command} ${value}`,
    );

    return this.getTrack(connection).applyConstraints({
      advanced: [{ [webrtcCommand]: value }],
    });
  }

  public get devicePresence(): IDevicePresencePayload {
    return {
      label: this.deviceId,
      available: true,
    };
  }

  prepareForSession(): Promise<void> {
    return Promise.resolve();
  }

  getComponentServices(_componentName: string): IComponentServicesPayload {
    const connection = this.getConnection();
    if (!connection) {
      return { services: {} };
    }

    const webRtcCapabilities = this.getTrack(connection).getCapabilities();

    const capabilities: INamedCapabilityDefinitions = Object.keys(
      webRtcCapabilities,
    ).reduce(
      (
        previous: INamedCapabilityDefinitions,
        capabilityName: string,
      ): INamedCapabilityDefinitions => {
        if (
          WebRTCToDcpMapping[capabilityName] &&
          // omit any capability that we don't want to control
          (!this.controls || this.controls[capabilityName])
        ) {
          previous[WebRTCToDcpMapping[capabilityName]] = {
            type: "range",
            access: "rw",
            //eslint-disable-next-line @typescript-eslint/no-explicit-any
            min: (webRtcCapabilities as any)[capabilityName].min,
            //eslint-disable-next-line @typescript-eslint/no-explicit-any
            max: (webRtcCapabilities as any)[capabilityName].max,
          } as ICapabilityDefinitionRange;
        }
        return previous;
      },
      {},
    );

    if (!this.controls) {
      // mounting only exists for virtual-DCP devices
      capabilities.mounting = {
        type: "choice",
        access: "rw",
        choiceType: "single",
        choiceList: [
          liveApiSockets.PtzMountings.Standard,
          liveApiSockets.PtzMountings.Inverted,
        ],
      };
    }

    return {
      services: {
        [SERVICE_NAME]: capabilities,
      },
    };
  }

  getComponentState(
    _componentName: string,
  ): IComponentStatePayload | undefined {
    if (Object.keys(this.settings).length === 0) {
      const connection = this.getConnection();
      if (!connection) {
        return { state: {} };
      }

      const webRTCSettings = this.getTrack(connection).getSettings();

      const settings: INamedCapabilityStateValues = Object.keys(
        webRTCSettings,
      ).reduce(
        (
          previous: INamedCapabilityStateValues,
          capabilityName: string,
        ): INamedCapabilityStateValues => {
          if (
            WebRTCToDcpMapping[capabilityName] &&
            // omit any capability that we don't want to control
            (!this.controls || this.controls[capabilityName])
          ) {
            previous[WebRTCToDcpMapping[capabilityName]] =
              //eslint-disable-next-line @typescript-eslint/no-explicit-any
              (webRTCSettings as any)[capabilityName];
          }
          return previous;
        },
        {},
      );

      if (!this.controls) {
        // mounting only exists for virtual-DCP devices
        settings.mounting = liveApiSockets.PtzMountings.Standard;
      }

      this.settings = settings;
    }

    return {
      state: {
        [SERVICE_NAME]: this.settings,
      },
    };
  }

  onSessionFinished(): Promise<void> {
    return Promise.resolve();
  }

  async requestComponent(
    componentName: string,
    request: IComponentRequestPayload,
  ): Promise<void> {
    console.log(
      { component: componentName },
      `PTZ RECEIVE ${JSON.stringify(request)}`,
    );

    await Promise.all(
      Object.keys(request.request[SERVICE_NAME]).map(
        (state: string): Promise<void> =>
          new Promise((resolve) => {
            if (state === "mounting") {
              this.settings.mounting = request.request[SERVICE_NAME].mounting;
              resolve();
            } else {
              this.applyRequest(
                state,
                request.request[SERVICE_NAME][state] as number,
              )
                .then(() => {
                  this.settings[state] = request.request[SERVICE_NAME][state];
                  resolve();
                })
                .catch((error: Error) => {
                  console.warn("Failed to apply request", error);
                  // carry on with any other requests in the message
                  resolve();
                });
            }
          }),
      ),
    );
    this.emitDeviceComponentChanged(componentName, "component:state");
  }

  get components() {
    return [this.deviceId];
  }

  public addConnection(connection: OutgoingVideo): void {
    this.connections.set(connection, true);

    const deleteConnection = (): void => {
      this.connections.delete(connection);

      connection.off("closed", deleteConnection);
      connection.off("error", deleteConnection);
    };

    connection.on("closed", deleteConnection);
    connection.on("error", deleteConnection);
  }

  private getConnection(): OutgoingVideo {
    // return the first connection in the map
    return this.connections.keys().next().value;
  }

  private getTrack(connection: OutgoingVideo): MediaStreamTrack {
    const track = connection.stream?.getVideoTracks()[0];
    if (!track) {
      throw new Error("No track available");
    }
    return track;
  }
}
