import React, { PropsWithChildren } from "react";
import Environment from "../models/Environment";

interface EnvironmentProviderProps {
  environment: Environment;
  children: React.ReactNode;
}

export const EnvironmentContext = React.createContext<Environment>(
  {} as Environment,
);

export const EnvironmentProvider: React.FC<
  PropsWithChildren<EnvironmentProviderProps>
> = ({ environment, children }) => {
  return (
    <EnvironmentContext.Provider value={environment}>
      {children}
    </EnvironmentContext.Provider>
  );
};
