import React from "react";
import { Box, ButtonBase, Grid } from "@mui/material";
import { colors } from "@proximie/components";
import {
  BRUSH_SIZES,
  useTelestration,
} from "../../contexts/telestration-context";

export const WidthPicker = () => {
  const { color, brushWidth, setBrushWidth } = useTelestration();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems={"center"}
      gap={"8px"}
      borderRadius={"10px"}
      paddingTop={"8px"}
      paddingBottom={"8px"}
      data-testid={"width-picker"}
    >
      {BRUSH_SIZES.map((bWidth, index) => {
        const border =
          bWidth === brushWidth
            ? `2px solid ${colors.Secondary500}`
            : `1px solid ${colors.WhiteA700}`;

        return (
          <Grid key={index} component={Box} xs={1}>
            <ButtonBase
              key={index}
              onClick={() => {
                setBrushWidth(bWidth);
              }}
              style={{
                width: 40,
                height: 40,
                borderRadius: "50%",
                padding: 10,
                border,
                backgroundColor: colors.Grey800,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "8px",
              }}
            >
              <Box
                width={bWidth}
                height={bWidth}
                borderRadius={"50%"}
                border={`1px solid ${colors.WhiteA700}`}
                boxSizing={"border-box"}
                bgcolor={color}
              />
            </ButtonBase>
          </Grid>
        );
      })}
    </Box>
  );
};
