export enum DeviceType {
  None,
  Dcp,
  Camera,
  Screen,
  Virtual,
  CameraPTZ,
  Microphone,
  Speaker,
  Incoming,
  DcpInvitee,
  PxLens,
}

export default DeviceType;
