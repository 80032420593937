import { Box, useTheme } from "@mui/material";
import React from "react";
import { TroubleShootingReportPanelStyled } from "./TroubleShootingReportPanel.style";
import { Subtitle3Typography } from "../shared/CustomTypography";

export type TroubleShootingReportPanelProps = {
  title?: string;
  flex?: string;
  overflowY?: string;
  minHeight?: string;
  padding?: string;
  children?: React.ReactNode;
};

export const TroubleShootingReportPanel = ({
  title,
  flex,
  overflowY,
  minHeight,
  padding,
  children,
}: TroubleShootingReportPanelProps) => {
  const { colors } = useTheme();

  return (
    <TroubleShootingReportPanelStyled
      flex={flex}
      overflowY={overflowY}
      minHeight={minHeight}
      padding={padding}
    >
      {title && (
        <Box display={"flex"} alignItems={"center"} alignSelf={"stretch"}>
          <Subtitle3Typography fontWeight={600} color={colors.BlackA700}>
            {title}
          </Subtitle3Typography>
        </Box>
      )}
      <Box
        display={"flex"}
        flexDirection={"row"}
        padding={"0"}
        width={"100%"}
        height={"100%"}
        data-testid={`${title}-report`}
      >
        {children}
      </Box>
    </TroubleShootingReportPanelStyled>
  );
};
