export interface ErrorResponse {
  readonly [key: string]: unknown;

  readonly error: {
    readonly statusCode: number; // 400, 401, 403, 404, ..., 500.
    readonly errorCode: string; // "BAD_REQUEST", "UNAUTHORIZED", "FORBIDDEN", "NOT_FOUND", ..., "INTERNAL_SERVER_ERROR".
    readonly message: string;

    readonly correlationId?: string;
    readonly timestamp?: string;
    readonly version?: string;
  };

  /** @deprecated  */
  readonly id: string;
  /** @deprecated  */
  readonly message: string;
}
