import React, {
  createContext,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
  FC,
} from "react";
import {
  AppName,
  createLogger,
  getEnvironment,
  isLocalOrE2EEnvironment,
  Logger,
} from "@proximie/common";
import overrideLogger from "../overrideLogger";
import { useAuthenticatedUser } from "./media-client-authenticated-user-context";
import { UseSocketIoContext } from "./socket-io-context";
import { liveApiSockets } from "@proximie/dataregion-api";
import { useUser } from "../hooks/useUser";
import { useEmbeddedClient } from "@proximie/components";
import { useFlag } from "@proximie/feature-flag-react-sdk";
import { FeatureFlags } from "../components/constants";
import { getSessionParamsFromURLQueryOrNull } from "../utils";
import Environment from "../models/Environment";

export interface LoggerContext {
  token: string | undefined;
}

interface LoggerProviderProps {
  children: ReactNode;
}

const LoggerContext = createContext<LoggerContext | null>(null);

function getOTLPUrl(env: Environment): string {
  if (isLocalOrE2EEnvironment(env.name)) {
    return `https://nr.proximieengineering.com/otel/web/v1/logs`;
  }
  return `https://nr.${env.domain}/otel/web/v1/logs`;
}

interface LoggerProviderProps {
  environment: Environment;
  children: React.ReactNode;
}

export const LoggerProvider: FC<PropsWithChildren<LoggerProviderProps>> = ({
  children,
  environment,
}) => {
  const useOtel = useFlag(FeatureFlags.USE_OTEL);
  const { isEmbedded, data } = useEmbeddedClient();
  const authContext = useAuthenticatedUser();
  const socketContext = UseSocketIoContext();
  const { user } = useUser(environment.apiUrl, environment.api.baseUrl);

  const [logger, setLogger] = useState<Logger | undefined>(undefined);

  const [token, setToken] = useState<string | undefined>(undefined);

  const loggerProviderValue = useMemo(() => ({ token }), [token]);

  const generateLogger = useCallback(
    ({
      newRelicLogUrl,
      mediaSessionId,
      environmentName,
    }: {
      mediaSessionId: string;
      newRelicLogUrl: string;
      environmentName: string;
    }) => {
      if (logger != null) {
        return;
      }

      const token = authContext?.token || "NO_TOKEN";

      setToken(token);

      const newLogger = createLogger({
        config: {
          auth: token,
          hostURL: newRelicLogUrl,
        },
        transport: useOtel ? "otlp" : "nr_http",
        mediaSessionId,
        app: AppName.LIVE,
        env: getEnvironment(environmentName),
        version: "1.0",
        pxkitId: data?.pxKitId,
      });

      setLogger(newLogger);

      if (
        !isLocalOrE2EEnvironment(environmentName) &&
        (newRelicLogUrl || useOtel)
      ) {
        overrideLogger(newLogger);
      }
    },
    [authContext?.token, data?.pxKitId, logger, useOtel],
  );

  useEffect(() => {
    if (authContext?.token && logger) {
      setToken(authContext?.token);

      logger?.setConfig?.({ auth: authContext?.token });
    }
  }, [authContext?.token, logger]);

  useEffect(() => {
    if (user?.profileReference && logger) {
      logger?.setMetadata?.({
        profileReference: user.profileReference,
        pxkitId: data?.pxKitId,
      });
    }
  }, [user?.profileReference, logger, isEmbedded, data]);

  useEffect(() => {
    const sessionParams = getSessionParamsFromURLQueryOrNull();

    if (
      !isLocalOrE2EEnvironment(environment.name) &&
      useOtel &&
      sessionParams
    ) {
      generateLogger({
        newRelicLogUrl: getOTLPUrl(environment),
        mediaSessionId: sessionParams.mediaSessionId,
        environmentName: environment.name,
      });
    } else {
      socketContext.on(
        liveApiSockets.MediaSessionEventType.sessionJoined,
        generateLogger,
      );

      return () => {
        socketContext.off(
          liveApiSockets.MediaSessionEventType.sessionJoined,
          generateLogger,
        );
      };
    }
  }, [generateLogger, useOtel, socketContext]);

  return (
    <LoggerContext.Provider value={loggerProviderValue}>
      {children}
    </LoggerContext.Provider>
  );
};
