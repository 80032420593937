import Device, { DeviceOptions } from "./Device";
import Connection, { ConnectionOptions } from "../Connections/Connection";
import ConnectionDcpVideoOutgoing from "../Connections/Dcp/ConnectionDcpVideoOutgoing";
import EndpointDcpSession from "../Endpoints/EndpointDcpSession";
import {
  IComponentServicesPayload,
  INamedCapabilityDefinitions,
  INamedCapabilityStateValues,
} from "@proximie/dcp";
import { SessionRemoteDevice } from "@proximie/dcp-mqtt";
import { FeedType, DeviceType } from "@proximie/common";
import QualityVideoOutgoingDcp from "../Quality/QualityVideoOutgoingDcp";

type DcpTypeType = Partial<Record<DcpType, boolean>>;

export enum DcpType {
  None,
  Stream,
  Control,
}

export interface DeviceDcpOptions extends DeviceOptions {
  dcpDevice: SessionRemoteDevice;
  component: string;
  hostDeviceId: string;
}

export default class DeviceDcp extends Device {
  public override readonly deviceType = DeviceType.Dcp;
  public override readonly mediaType = FeedType.Camera;
  public serviceName = "";
  public readonly dcpTypes: DcpTypeType = {};

  constructor(deviceId: string, public options: DeviceDcpOptions) {
    super(deviceId, options);

    let services: IComponentServicesPayload | undefined;
    try {
      services = options.dcpDevice.getComponentServices(options.component);
    } catch {
      // ignore error
    }

    // for WALL-E we need to use the presence assembly - so if this field is
    // present we'll mark the device as a "controller"
    if (options.dcpDevice.devicePresence.assembly) {
      this.dcpTypes[DcpType.Control] = true;
    }

    Object.entries(services?.services || []).forEach(
      ([serviceName, service]: [string, INamedCapabilityDefinitions]): void => {
        Object.keys(service).forEach((capabilityName: string): void => {
          // we just use the last service name for now
          this.serviceName = serviceName;

          switch (capabilityName) {
            case "STREAM":
              this.dcpTypes[DcpType.Stream] = true;
              break;
            case "PAN":
            case "TILT":
            case "ZOOM":
              this.dcpTypes[DcpType.Control] = true;
              break;
            default:
              console.warn(
                { deviceId },
                "Unexpected capability=",
                capabilityName,
              );
          }
        });
      },
    );

    this.label =
      services?.label || options.dcpDevice.devicePresence.label || "UNKNOWN";

    options.dcpDevice.on("component:services", this.componentServicesHandler);
    options.dcpDevice.on("component:state", this.componentStateHandler);
    options.dcpDevice.on("component:off", this.componentOffHandler);
  }

  private componentServicesHandler = (componentName: string): void => {
    if (componentName === this.options.component) {
      console.debug(
        { deviceId: this.deviceId },
        `DeviceDcp:componentServicesHandler - component=${componentName} services=${JSON.stringify(
          this.services,
        )}`,
      );
      this.emit("services", this.services);
    }
  };

  private componentStateHandler = (componentName: string) => {
    if (componentName === this.options.component) {
      console.debug(
        { deviceId: this.deviceId },
        `DeviceDcp:componentStateHandler - component=${componentName} state=${JSON.stringify(
          this.state,
        )}`,
      );
      this.emit("state", this.state);
    }
  };

  private componentOffHandler = (componentName: string): void => {
    if (componentName === this.options.component) {
      console.debug(
        { deviceId: this.deviceId },
        `DeviceDcp:componentOffHandler - component=${componentName}`,
      );

      this.options.dcpDevice.off(
        "component:services",
        this.componentServicesHandler,
      );
      this.options.dcpDevice.off("component:state", this.componentStateHandler);
      this.options.dcpDevice.off("component:off", this.componentOffHandler);

      this.emit("closed");
    }
  };

  public override invoke(
    endpoint: EndpointDcpSession,
    streamId: string,
    options: ConnectionOptions,
  ): Connection | null {
    return new ConnectionDcpVideoOutgoing(endpoint, this, streamId, {
      ...options,
      params: {
        ...options.params,
        // DCP devices cannot do masking
        capabilities: {
          ...options.params?.capabilities,
          canMask: false,
        },
        devices: [
          {
            deviceId: this.deviceId,
            component: this.options.component,
            services: this.serviceName,
          },
        ],
      },
      hostDeviceId: this.options.hostDeviceId,
      quality: new QualityVideoOutgoingDcp({
        bitrate: endpoint.config.bitrate,
      }),
      globals: options.globals,
    });
  }

  // return a string that it unique across all devices
  get uniqueId(): string {
    return `${this.deviceType}:${this.deviceId}:${this.options.component}`;
  }

  override get state(): INamedCapabilityStateValues | undefined {
    let state: INamedCapabilityStateValues | undefined;
    try {
      state = this.options.dcpDevice.getComponentState(this.options.component)
        ?.state[this.serviceName];
    } catch {
      // ignore error
    }
    return state;
  }

  override get services(): INamedCapabilityDefinitions | undefined {
    let service: INamedCapabilityDefinitions | undefined;
    try {
      service = this.options.dcpDevice.getComponentServices(
        this.options.component,
      )?.services[this.serviceName];
    } catch {
      // ignore error
    }
    return service;
  }

  public hasDcpType(dcpType: DcpType): boolean {
    return !!this.dcpTypes[dcpType];
  }
}
