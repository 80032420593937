/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  MouseEvent,
} from "react";
import { Link } from "react-router-dom";
import {
  AccentButton,
  DangerButton,
  GhostButton,
  PrimaryButton,
  SecondaryButton,
  SecondaryDangerButton,
} from "./Button.style";

const VariantsMapping = {
  primary: PrimaryButton,
  secondary: SecondaryButton,
  accent: AccentButton,
  danger: DangerButton,
  secondaryDanger: SecondaryDangerButton,
  ghost: GhostButton,
};

export interface ButtonProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  id?: string;
  "data-testid"?: string;
  "data-cy"?: string;
  type?: "button" | "submit";
  variant?:
    | "primary"
    | "secondary"
    | "accent"
    | "danger"
    | "secondaryDanger"
    | "ghost";
  size?: "small" | "medium" | "large";
  style?: Record<string, any>;
  label?: string;
  disabled?: boolean;
  startIcon?: React.ReactNode;
  children?: React.ReactNode;
  onClick?: (event: MouseEvent) => void;
  to?: string;
}

export function Button({
  variant = "primary",
  size = "medium",
  startIcon,
  children,
  to,
  ...props
}: ButtonProps): JSX.Element {
  const className = `size-${size}`;
  const StyledButton = VariantsMapping[variant];
  const commonProps = {
    className,
    ...(props as unknown as ButtonHTMLAttributes<HTMLButtonElement>),
  };

  if (to) {
    return (
      <Link to={to} style={{ display: "contents" }}>
        <StyledButton {...commonProps}>
          {startIcon}
          {children}
        </StyledButton>
      </Link>
    );
  }

  return (
    <StyledButton {...commonProps}>
      {startIcon}
      {children}
    </StyledButton>
  );
}
