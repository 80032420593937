export const environment = {
  name: "development",
  dnsRecordName: "proximieengineering.com",
  domain: "proximieengineering.com",
  apiUrl: "https://my.proximieengineering.com",
  oAuth: {
    clientId: "RbSwROi1wEE4fn7NDOT517KwQEXgKj0H",
    domain: "auth.proximieengineering.com",
    audience: "proximie-web",
    connection: "",
  },
  api: {
    baseUrl: "https://api.proximieengineering.com",
  },
  assets: {
    flavorUrl: "https://live.proximieengineering.com/assets/config",
  },
  featureFlag: {
    url: "https://featureflags.proximieengineering.com/proxy",
    appName: "media-client",
    apiKey: "2CRUOHsxR6ESFQiBM4AU7Fts0IcUS0P",
    environment: "development",
  },
  permissions: "['support-ticket:create','speciality:read',]",
  supportLines: "{US:{link:'tel:+1888-206-3779',label:'+1 888-206-3779'}, 'UK/EU':{link:'tel:+448000584306',label:'+44 800 058 4306'}, 'WhatsApp':{label: '+44 754 393 0252', link: 'https://wa.me/447543930252'}}",
};
