import { ConnectionMetadata } from "@proximie/common";

export type SessionParticipant = {
  id: string;
  profileId?: number;
  name: string;
  email: string;
  invitationStatus?: string;
  role?: string;
  metadata?: ConnectionMetadata;
  streamId?: string;
};

export type SessionParticipants = {
  participants: SessionParticipant[];
};
