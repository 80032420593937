/* eslint-disable @typescript-eslint/no-explicit-any */
import ConnectionJanus from "./ConnectionJanus";
import { ConnectionType, Direction } from "../Connection";
import { EndpointType } from "../../Endpoints/Endpoint";
import { JanusJS } from "@proximie/janus-gateway";
import { ConnectionMetadata } from "@proximie/common";
import { PrxMessage } from "./message";

export default class ConnectionJanusVideoIncoming extends ConnectionJanus {
  override connectionType = ConnectionType.JanusVideoIncoming;
  override direction = Direction.Incoming;

  protected attachOptions: JanusJS.PluginOptions = {
    plugin: "OVERWRITE_ME",
    ...this.defaultAttachCallbacks,
    onmessage: this.onMessage.bind(this),
    onremotestream: this.onRemoteStream.bind(this),
  };

  private onMessage(message: PrxMessage, jsep?: JanusJS.JSEP): void {
    console.debug(
      {
        streamId: this.streamId,
      },
      `VideoIn:onMessage: message=${JSON.stringify(message)} jsep=${!!jsep}`,
      jsep,
    );

    if (jsep) {
      this.handle?.createAnswer({
        jsep,
        media: { audio: false, videoSend: false },
        success: (jsep2: JanusJS.JSEP) => {
          console.debug(
            {
              streamId: this.streamId,
            },
            "VideoIn:onMessage: Sending start",
            jsep2,
          );
          this.emit("connecting");
          this.handle?.send({
            message: {
              request: "start",
            },
            jsep: jsep2,
          });
        },
        error: (error: any) => {
          console.warn(
            {
              streamId: this.streamId,
            },
            "VideoIn:onMessage Create answer - error=",
            error,
          );
          this.close(new Error(error));
        },
      });
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const event = message["videoroom"];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (message["error"]) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      console.warn(
        {
          streamId: this.streamId,
        },
        "VideoIn:onMessage: error=",
        message["error"],
      );
      this.close(new Error(message["error"]));
      return;
    }

    switch (event) {
      case "attached":
        console.debug(
          {
            streamId: this.streamId,
          },
          "VideoIn:onMessage: attached",
        );
        return;
      case "event":
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (message["substream"] !== undefined && this.quality) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this.quality.substream = message["substream"];
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (message["temporal"] !== undefined && this.quality) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this.quality.temporal = message["temporal"];
        }
        return;
    }

    console.warn(
      {
        streamId: this.streamId,
      },
      "VideoIn:onMessage Unknown onMessage",
      message,
    );
  }

  protected override onRemoteStream(stream: MediaStream): void {
    super.onRemoteStream(stream);

    this.stream = stream;
  }

  protected override join(params: ConnectionMetadata): Promise<void> {
    console.debug(
      {
        streamId: this.streamId,
      },
      "ConnectionJanusVideoIncoming:join",
      params,
    );

    return new Promise<void>((resolve, reject) => {
      const register = {
        request: "join",
        room: this.mediaSessionId,
        ptype: "subscriber",
        feed: this.streamId,
      };

      this.handle?.send({
        message: register,
        success: resolve,
        error: (error: string) => reject(new Error(error)),
      } as unknown as JanusJS.PluginMessage);
    });
  }

  override kick(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const request = {
        message: {
          request: "kick",
          room: this.endpoints[EndpointType.Video]?.mediaSessionId || "",
          id: this.streamId,
        },
        success: () => {
          resolve();
        },
        error: (error: any) => {
          console.warn(
            {
              streamId: this.streamId,
            },
            "kick - other participant's video feed, error=",
            error,
          );
          reject(error);
        },
      };
      this.handle?.send(request);
    }).finally(() => {
      return super.kick();
    });
  }
}
