import DeviceType from "./DeviceType";
import FeedType from "./FeedType";
import DeviceMetadata from "./DeviceMetadata";

type Capabilities = {
  canMask?: boolean;
  canPTZ?: boolean;
  [key: string]: unknown;
};

export type ConnectionMetadata = {
  deviceType?: DeviceType;
  mediaType?: FeedType;
  label?: string;
  devices?: DeviceMetadata[];
  capabilities?: Capabilities;
  userUUID?: string; //deprecated
  userId?: string;
  order?: string;
  [key: string]: unknown;
};

export default ConnectionMetadata;
